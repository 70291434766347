<template>
  <el-main>
    <div class="title_tab">
      {{ modsname }}
      <div style="float: right; line-height: 35px">
      </div>
    </div>
    <div style="clear: both"></div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-input style="width: 180px;" placeholder="请输入用户名" prefix-icon="iconfont icon-user" v-model="userName" class="username">
            </el-input>
            <el-select v-model="is_old" placeholder="活跃状态" clearable style="width: 120px;" >
              <el-option  label="不活跃用户" :value="1">
              </el-option>
            </el-select>
            <!-- <el-input placeholder="请输入手机号码" prefix-icon="el-icon-phone" v-model="tel" class="tel">
            </el-input> -->
            <el-select v-model="selectUserTypeVal" placeholder="用户类型" clearable style="width: 120px;">
              <el-option v-for="item in selectUserType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="selectVlues" placeholder="用户状态" clearable style="width: 120px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker v-model="ScreateTime" type="date" placeholder="注册时间" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button style="margin-left: 12px; margin-top: 10px" icon="el-icon-search" @click="searchUser()"
              v-no-more-click :loading="vloading">搜 索
            </el-button>
            <el-button style="margin-left: 12px; margin-top: 10px" icon="el-icon-refresh" @click="getUser()">刷 新
            </el-button>
            <el-input placeholder="最低汇率"  :disabled="!isShow" prefix-icon="el-icon-coin" v-model="bklv" class="bklvInput">
            </el-input>
            <el-input placeholder="USD提现汇率" :disabled="!isShow" prefix-icon="el-icon-coin" v-model="usddraws" class="bklvInput">
            </el-input>
            <el-button style=" margin-top: 10px" v-show="isShow" icon="el-icon-refresh" @click="isOpenusd()">保存汇率
            </el-button>
            <el-descriptions title="全局变量" :column="3" style="
                padding: 10px;
                margin-top: 20px;
                border: 1px #c0c4cc solid;
                border-radius: 4px;
              ">
              <el-descriptions-item label="正常卡总数" :span="1">
                {{ normal_total }}
              </el-descriptions-item>
              <el-descriptions-item label="锁定卡总数" :span="1">
                {{ lock_total }}
              </el-descriptions-item>
              <el-descriptions-item label="销毁卡总数" :span="1">
                {{ destroy_total }}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="操作" :span="1">
                <el-button @click="getCardStatistic('rf')">刷 新</el-button>
              </el-descriptions-item> -->
            </el-descriptions>
            <el-descriptions title="全局配置" :column="5" border style="
                padding: 10px;
                margin-top: 20px;
                border: 1px #c0c4cc solid;
                border-radius: 4px;
              ">
              <!-- <el-descriptions-item label="是否兑换美金">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭"
                  v-model="isopenusd" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item> -->
              <!-- <el-descriptions-item label="卡转出手续费">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="isgreduce" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item> -->
              <el-descriptions-item label="屏蔽大陆">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="iplimit" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item>
              
              <el-descriptions-item label="复星充值">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="rmbisok" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item>
              
              <!-- <el-descriptions-item label="统统跨境">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="usdisok" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item> -->
              
              
              <!-- <el-descriptions-item label="USD提现汇率" label-align="center">
                <el-input v-model="usddraws" :disabled="!isShow" placeholder="请输入内容"></el-input>
              </el-descriptions-item> -->
              <!-- <el-descriptions-item label="CNY提现汇率">
                <el-input v-model="cnydraws" :disabled="!isShow" placeholder="请输入内容" ></el-input>
              </el-descriptions-item> -->
              <el-descriptions-item label="汇付充值">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="isthirdusd" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item>
              <el-descriptions-item label="自助销卡">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="isautodel" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item>
              <el-descriptions-item label="邮箱登录">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭" :disabled="initLoading"
                  v-model="login_type" @change="isOpenusd()" :active-value="1" :inactive-value="2"></el-switch>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="操作">
                <el-button @click="isOpenusd()">保存汇率</el-button>
              </el-descriptions-item> -->
            </el-descriptions>
          </div>
          <div class="user_box">
            <u-table ref="plTable" :border="false" :default-sort="{ prop: 'date', order: 'descending' }" use-virtual
              :row-height="60" showBodyOverflow="title" showHeaderOverflow="title" :data="tableData"
              v-loading="vloading" style="width: 100%" height="550" max-height="550" size="small" highlight-current-row>
              <!-- id- ids，用户名-name，手机号码-tel，上级名称-superior，用户状态-status，注册时间-date，余额-balance，拒付率-dishonor，操作 -->
              <u-table-column label="id" prop="id" width="55">
              </u-table-column>
              <u-table-column label="国家" prop="isreal" width="60">
                <template slot-scope="scope">
                  <div v-if="scope.row.isreal === 2" style="display: flex;justify-content: center;align-items: center;">
                    <img src="@/images/china.png" alt="" style="width: 44px;height: 24px">
                  </div>
                  <div v-if="scope.row.isreal === 4" style="display: flex;justify-content: center;align-items: center;">
                    <img src="@/images/dollar.png" alt="" style="width: 44px;height: 24px">
                  </div>
                </template>
              </u-table-column>
              <u-table-column label="用户名" prop="users" width="105">
              </u-table-column>
              <!-- <u-table-column label="手机号码" prop="users" width="105">
              </u-table-column> -->
              <u-table-column label="用户类型" :label-width="formLabelWidth" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1"> 普通用户 </el-tag>
                  <el-tag v-else-if="scope.row.types === 2" type="warning">
                    代理商
                  </el-tag>
                  <el-tag v-else-if="scope.row.types === 3" type="danger">
                    管理员
                  </el-tag>
                </template>
              </u-table-column>
              <!-- <el-table-column label="上级名称" prop="pName">
              </el-table-column> -->
              <u-table-column label="操作时间" prop="updatetime" width="150">
              </u-table-column>
              <u-table-column label="注册时间" prop="createtime" width="150">
              </u-table-column>
              <!-- <u-table-column label="余额(CNY)" sortable prop="cnymoney" width="110">
              </u-table-column> -->
              <u-table-column label="余额(USD)" sortable prop="usdmoney" width="120">
              </u-table-column>
              <u-table-column label="拒付率" sortable prop="relv" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.relv < 50">
                    {{ scope.row.relv }}%
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.relv >= 50">
                    {{ scope.row.relv }}%
                  </el-tag>
                </template>
              </u-table-column>
              <!-- <el-table-column label="拒付金额" sortable prop="errmoney" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.errmoney < 5000">
                    {{ scope.row.errmoney }}
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.errmoney >= 5000">
                    {{ scope.row.errmoney }}
                  </el-tag>
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="失败金额" sortable prop="remoney" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.remoney < 1000">
                    {{ scope.row.remoney }}
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.remoney >= 1000">
                    {{ scope.row.remoney }}
                  </el-tag>
                </template>
              </el-table-column> -->
              <!-- <u-table-column label="充值总金额(CNY)" sortable prop="cnyallmoney" width="150">
              </u-table-column> -->
              <u-table-column label="充值总金额(USD)" sortable prop="usdallmoney" width="150">
              </u-table-column>
              <!-- 用户状态标签 -->
              <u-table-column prop="status" label="用户状态" width="80">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="success">
                    正常
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="info">
                    停用
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 3" type="warning">
                    待审核
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 4" type="danger">
                    不通过
                  </el-tag>
                  <!-- <el-tag :type="scope.row.status === 1 ? 'primary' : 'success'">{{scope.row.status}}
                  </el-tag> -->
                </template>
              </u-table-column>
              <u-table-column label="邀请人数" prop="invinum" width="70">
              </u-table-column>
              <u-table-column label="邀请码" prop="invitation" width="90">
              </u-table-column>
              <!-- 前端搜索框 -->
              <u-table-column label="操作" width="1400">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="handleEdit(scope.row.id, scope.row)">编辑
                  </el-button>
                  <el-button size="mini" type="success" @click="DlogIps = true" plain>解锁IP</el-button>
                  <el-button size="mini" type="primary" @click="getEditReal(scope.row.id, scope.row)">修改身份信息
                  </el-button>
                  <!-- <el-button size="mini" v-no-more-click type="success" v-show="scope.row.types != 3 && isShow == true"
                    @click="RecodCz(scope.row.id, 'cny')">CNY充值
                  </el-button> -->
                  <el-button size="mini" v-no-more-click type="primary" v-show="scope.row.types != 3"
                    @click="searchCards(scope.row)" plain>查询卡</el-button>
                  <el-button size="mini" v-no-more-click type="danger" v-show="scope.row.types != 3 && isShow == true"
                    @click="RecodCz(scope.row.id, 'usd')">USD充值
                  </el-button>
                  <el-button size="mini" v-no-more-click type="primary" v-show="scope.row.types != 3"
                    @click="searchRecod(scope.row.id)" plain>充值记录</el-button>
                  <el-button size="mini" v-no-more-click type="warning" v-show="scope.row.types == 1 && isShow == true"
                    @click="addProxy(scope.row.id)">设为代理
                  </el-button>
                  <el-button size="mini" v-no-more-click type="info" v-show="scope.row.types == 2 && isShow == true"
                    @click="delProxy(scope.row.id)">取消代理
                  </el-button>
                  <el-button size="mini" v-no-more-click type="primary" v-show="scope.row.types != 3" @click="
                    (DialogMngPanel = true), getUserRecord(scope.row.id)
                    " plain>拒付面板
                  </el-button>
                  <!-- <el-button size="mini" v-no-more-click type="danger" v-if="scope.row.types != 3"
                    @click="handleDelete(scope.row.id, scope.row)">删除</el-button> -->
                  <el-button size="mini" v-no-more-click type="primary" v-show="scope.row.types == 3"
                    @click="glyDloag = true" plain>安全策略</el-button>
                  <el-button size="mini" v-no-more-click type="primary" @click="openCarModal(scope.row)"
                    plain>配置卡段</el-button>
                  <span v-show="scope.row.types != 3 && isShow == true" class="carswitch_span">
                    <el-switch v-model="scope.row.limit_recharge" active-color="#13ce66" :active-value="1"
                      :inactive-value="2" inactive-text="反诈" active-text="限制" inactive-color="#409eff" @change="(a) => {
                        limitRechargeChange(scope.row, a);
                      }
                        ">
                    </el-switch>
                  </span>
                  <!-- 加密货币充值的开关 -->
                  <span v-show="scope.row.types != 3" class="carswitch_span">
                    <el-switch v-model="scope.row.limit_utrecharge" active-color="#13ce66" :active-value="1"
                      :inactive-value="2" inactive-text="货币" active-text="限制" inactive-color="#409eff" @change="(a) => {
                        limitRechargeUTChange(scope.row, a);
                      }
                        ">
                    </el-switch>
                  </span>
                  <!-- 设置用户登录方式 -->
                   <span v-show="scope.row.types != 3" class="carswitch_span">
                    <el-switch v-model="scope.row.login_type" active-color="#13ce66" :active-value="1"
                      :inactive-value="2" inactive-text="非邮箱" active-text="邮箱" inactive-color="#409eff" @change="(a) => {
                        setLoginType(scope.row, a);
                      }
                        ">
                    </el-switch>
                   </span>
                  <!-- 设置代理商按钮 -->
                  <span v-show="scope.row.types == 2 && isShow == true" class="carswitch_span">
                    <el-switch v-model="scope.row.agent_edit" active-color="#13ce66" :active-value="2"
                      :inactive-value="1" inactive-text="代理编辑" active-text="限制" inactive-color="#409eff" @change="(a) => {
                        limitProxy(scope.row, a);
                      }
                        ">
                    </el-switch>
                  </span>
                </template>
              </u-table-column>
            </u-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 200, 300, 500, 5000, 10000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="tableTotal">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 充值弹出框 -->
    <el-dialog custom-class="ls" title="用户充值" :close-on-click-modal="false" :visible.sync="dialogCzVisible" width="25%">
      <template>
        <el-radio-group v-model="czType">
          <el-radio v-if="czTypes == 'cny'" label="1" border>CNY</el-radio>
          <el-radio v-if="czTypes == 'usd'" label="2" border>USD</el-radio>
        </el-radio-group>
      </template>
      <div style="margin-top: 10px">
        <el-radio-group v-model="czActionType">
          <el-radio label="1" border>充值</el-radio>
          <el-radio label="2" border>扣除</el-radio>
        </el-radio-group>
      </div>
      <div style="margin-top: 10px">
        <el-input v-model="czJ" placeholder="请输入金额"></el-input>
      </div>
      <div style="margin-top: 10px">
        <el-input v-model="remark" placeholder="请输入备注"></el-input>
      </div>
      <div style="margin-top: 10px">
        <el-button @click="RecodCz()" v-no-more-click>确 定</el-button>
        <el-button plain @click="dialogCzVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 编辑用户弹出框 -->
    <el-dialog custom-class="ls" title="编辑用户" :visible.sync="dialogFormVisible" width="60%" top="5vh">
      <el-form :model="EditUserform">
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.users" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.phones" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上级名称" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.pusers" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="注册时间" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.createtime" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="修改密码" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.newPass" autocomplete="off" show-password></el-input>
        </el-form-item>
        <!-- <el-form-item label="余额(CNY)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.cnymoney" autocomplete="off" disabled></el-input>
        </el-form-item> -->
        <el-form-item label="余额(USD)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.usdmoney" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="是否实名认证" :label-width="formLabelWidth">
          <!-- <el-switch v-model="EditUserform.isreal" :active-value="2" :inactive-value="1">
          </el-switch> -->

          <el-radio-group v-model="EditUserform.isreal">
            <el-radio :label="1" disabled>未实名</el-radio>
            <el-radio :label="2" disabled>大陆实名</el-radio>
            <el-radio :label="3" disabled>审核中</el-radio>
            <el-radio :label="4" disabled>海外实名</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider content-position="left" v-if="showuploadqrcode">代理商</el-divider>
        <el-form-item label="二维码上传" :label-width="formLabelWidth" v-if="showuploadqrcode">
          <el-upload action="#" list-type="picture-card" :http-request="handleUpload"
            accept="image/jpeg, image/png, image/jpg" :limit="1" :before-upload="uploadFile" :on-remove="handleRemove"
            :file-list="fileList">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-divider content-position="left">充值开卡</el-divider>
        <el-form-item label="开卡上限" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.setcard" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="人民币充值手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.wx_char"></el-input>
        </el-form-item> -->
        <el-form-item label="复星充值手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.service_usdd"></el-input>
        </el-form-item>
        <el-form-item label="加密充值手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.service_usdt"></el-input>
        </el-form-item>
        <el-form-item label="汇付充值手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.service_usddd"></el-input>
        </el-form-item>
        <el-form-item label="汇率优惠手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.discount_lv"></el-input>
        </el-form-item>
        <el-form-item label="是否开启广告手续费">
          <el-switch v-model="EditUserform.is_service_adv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="广告手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.service_adv"></el-input>
        </el-form-item>
        <el-form-item label="在线人数" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.lognum" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="人民币返点(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.fenlv" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="美金返点(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.usdfenlv" autocomplete="off"></el-input>
        </el-form-item>
        <el-divider content-position="left">产品（一）</el-divider>
        <el-form-item label="是否以用户设置为准">
          <el-switch v-model="EditUserform.is_card_openlv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="开卡费" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.opencardlv" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否以用户设置为准">
          <el-switch v-model="EditUserform.is_card_uselv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="卡充值手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.service_char" autocomplete="off"></el-input>
        </el-form-item>
        <el-divider content-position="left">产品（二）</el-divider>
        <el-form-item label="是否以用户设置为准">
          <el-switch v-model="EditUserform.gis_card_openlv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="开卡费" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.gopencardlv" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否以用户设置为准">
          <el-switch v-model="EditUserform.gis_card_uselv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="卡充值手续费(%)" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.gservice_card" autocomplete="off"></el-input>
        </el-form-item>
        <el-divider content-position="left">产品（三）</el-divider>
        <el-form-item label="是否以用户设置为准">
          <el-switch v-model="EditUserform.pis_card_openlv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="开卡费" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.popencardlv" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否以用户设置为准">
          <el-switch v-model="EditUserform.pis_card_uselv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item label="卡充值手续费" :label-width="formLabelWidth">
          <el-input v-model="EditUserform.pservice_card" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户状态" :label-width="formLabelWidth" prop="status">
          <el-select placeholder="用户状态" v-model="EditUserform.status" @change="changeStatus">
            <el-option v-for="item in options" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeEditModal">取 消</el-button>
        <el-button @click="processUser()">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 查询卡片弹窗 -->
    <el-dialog title="查询卡片" custom-class="ls" :visible.sync="searchCardsFormVisible" width="90%">
      <!-- 查询卡片表单 -->
      <el-form :model="form_queryCards" ref="form_queryCardsRef">
        <!-- 卡号 -->
        <el-input v-model="form_queryCards.cardNo" prefix-icon="el-icon-bank-card" placeholder="请输入卡号">
        </el-input>
        <!-- 开卡日期查询 -->
        <el-date-picker v-model="selectTimes" type="date" placeholder="申请日期" format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
        <!-- 卡片状态 -->
        <el-select v-model="cardsTypesVal" placeholder="卡片状态">
          <el-option v-for="item in cardsTypes" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" @click="searchCards()">搜 索</el-button>
        <el-button icon="el-icon-refresh" @click="searchCards('rf')">刷 新</el-button>
      </el-form>
      <!-- 查询卡片表格 -->
      <el-table :data="cardsData" style="width: 100%" height="550" max-height="550" size="small"
        @expand-change="(row) => UserCarShoplist(row)" highlight-current-row>
        <!-- 展开卡片详情表格 -->
        <el-table-column type="expand" label="卡交易记录" width="90">
          <template slot-scope="props">
            <template>
              <el-table :header-cell-style="{ background: '#909399', color: '#ffffff' }" :data="cardsRechageRecodeData"
                style="width: 100%" size="small" highlight-current-row>
                <el-table-column prop="card_num" label="卡号" width="180">
                </el-table-column>
                <el-table-column prop="shopname" label="商户名称" width="120" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="createtime" label="订单时间" :show-overflow-tooltip="true" width="150">
                </el-table-column>
                <el-table-column prop="money" label="消费金额" width="100">
                </el-table-column>
                <!-- 交易类型 -->
                <el-table-column label="交易类型" width="160">
                  <template slot-scope="scope">
                    <!-- <el-tag
                      v-if="scope.row.event_type === '6810'"
                      effect="dark"
                      type="success"
                    >
                      消费授权(通过)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6890'"
                      effect="dark"
                      type="danger"
                    >
                      消费授权(拒绝)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6510'"
                      effect="dark"
                      type="success"
                    >
                      退款授权(通过)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6590'"
                      effect="dark"
                      type="danger"
                    >
                      退款授权(拒绝)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6930'"
                      effect="dark"
                      type="success"
                    >
                      消费授权冲正
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6940'"
                      effect="dark"
                      type="danger"
                    >
                      退款授权冲正
                    </el-tag> -->
                    <el-tag v-if="scope.row.event_type === 'auth'" effect="dark" type="success">
                      消费
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'corrective_auth'" effect="dark" type="success">
                      纠正授权
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'verification'" effect="dark" type="success">
                      验证
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'void'" effect="dark" type="warning">
                      撤销
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'refund'" effect="dark" type="warning">
                      退款
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'corrective_refund'" effect="dark" type="danger">
                      效正退款
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'recharge'" effect="dark" type="success">
                      充值
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'recharge_return'" effect="dark" type="success">
                      卡余额退还
                    </el-tag>
                    <el-tag v-else-if="
                      scope.row.event_type === 'discard_recharge_return'
                    " effect="dark" type="warning">
                      销卡退回
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'service_fee'" effect="dark" type="success">
                      服务费
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'refund_reversal'" effect="dark" type="danger">
                      退款撤销
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'Auth'" effect="dark" type="success">
                      授权消费
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'Reversal'" effect="dark" type="warning">
                      退款
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'card_out'" effect="dark" type="warning">
                      卡转出
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'card_in'" effect="dark" type="warning">
                      卡转入
                    </el-tag>
                  </template>
                </el-table-column>
                <!-- 交易状态 -->
                <el-table-column prop="statu" label="交易状态" width="100">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.statu === 1" type="success">
                      交易成功
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 2" type="danger">
                      交易失败
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 3" type="warning">
                      交易中
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 4" type="success">
                      交易撤销成功
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 5" type="danger">
                      交易撤销失败
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="desct" label="交易描述" :show-overflow-tooltip="true" width="220">
                  <template slot-scope="scope">
                    {{ seleDesTop(scope.row.desct) }}
                  </template>
                </el-table-column>
                <el-table-column prop="moneytype" label="交易币种" width="120">
                </el-table-column>
              </el-table>
              <!-- 卡交易记录表格分页 -->
              <el-pagination class="pagesRecod" align="center" @size-change="CardRecodhandleSizeChange"
                @current-change="CardRecodhandleCurrentChange" :current-page="CardRecodcurrentPage"
                :page-sizes="[5, 10, 15, 20]" :page-size="CardRecodpageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="cardsRechageRecodeDataTotal">
              </el-pagination>
            </template>
          </template>
        </el-table-column>
        <!-- 卡类型 -->
        <el-table-column prop="ctype" label="卡类型" width="90">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.ctype == '共享卡'" type="danger">
              {{ scope.row.ctype }}
            </el-tag>
            <el-tag v-else type="success">
              {{ scope.row.ctype }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- 卡号 -->
        <el-table-column prop="num" label="卡号" width="150"> </el-table-column>
        <!-- 币种 -->
        <el-table-column prop="types" label="币种" width="90">
        </el-table-column>
        <!-- 开卡日期 -->
        <el-table-column prop="createtime" label="开卡日期" width="150">
        </el-table-column>
        <!-- 卡状态 -->
        <el-table-column label="卡状态" width="90">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="success"> 正常 </el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="danger">
              锁卡
            </el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="info">
              销卡
            </el-tag>
          </template>
        </el-table-column>
        <!-- 卡备注 -->
        <el-table-column prop="remark" label="卡备注" width="220" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- 卡余额 -->
        <el-table-column label="可用余额" width="150" sortable>
          <template slot-scope="scope">
            {{ scope.row.usdmoney }}
          </template>
        </el-table-column>
        <!-- 查询卡片操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.status == 1 && scope.row.status != 3" type="info"
              @click="LockCard(scope.row)">锁卡</el-button>
            <el-button size="mini" v-if="scope.row.status == 2 && scope.row.status != 3" v-no-more-click type="success"
              @click.native.stop="jk(scope.row)">解卡</el-button>
            <el-button v-if="scope.row.status != 3" v-no-more-click type="primary" icon="el-icon-refresh-left"
              @click.native.stop="SyncMoney(scope.row)" size="mini">同步</el-button>
            <el-button size="mini" v-no-more-click type="primary" v-if="
              scope.row.istoslide != 2 &&
              scope.row.status == 1 &&
              scope.row.status != 3
            " @click.native.stop="transferCard(scope.row)">转出
            </el-button>
            <el-button size="mini" v-if="scope.row.status != 3" type="danger" @click="delUserCard(scope.row)">销卡
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 查询卡片分页 -->
      <el-pagination align="center" @size-change="searchCardhandleSizeChange"
        @current-change="searchCardhandleCurrentChange" :current-page="searchCardcurrentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="searchCardpageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="cxcardTotal">
      </el-pagination>
    </el-dialog>
    <!-- 充值记录弹窗 -->
    <el-dialog title="流水记录" custom-class="ls" :visible.sync="consumptionFormVisible" width="90%">
      <!-- 流水记录表单 -->
      <el-form :model="form_record" ref="form_recordFormRef" :label-position="labelPosition">
        <!-- 订单号 -->
        <el-input v-model="form_record.orderNo" prefix-icon="el-icon-tickets" placeholder="请输入订单号">
        </el-input>
        <!-- 充值渠道 -->
        <el-select v-model="rechageQd" placeholder="充值渠道" clearable>
          <el-option v-for="item in qdOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 订单状态 -->
        <el-select v-model="rechageStatus" placeholder="充值状态" clearable>
          <el-option v-for="item in orderStatus" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 日期选择 -->
        <el-date-picker v-model="recordDataTimes" type="daterange" align="right" unlink-panels range-separator="-"
          start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2" format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-button style="margin-left: 40px" icon="el-icon-search" @click="searchRecod()">搜 索
        </el-button>
        <el-button icon="el-icon-refresh" @click="qkcz(), searchRecod()">刷 新</el-button>
      </el-form>
      <!-- 流水记录表格 -->
      <el-table :data="userRecod" style="width: 100%" height="550" max-height="550" size="small" highlight-current-row>
        <!-- id- ids，用户名-name，手机号码-tel，上级名称-superior，用户状态-status，注册时间-date，余额-balance，拒付率-dishonor，操作 -->
        <el-table-column label="订单编号" prop="order_num"> </el-table-column>
        <el-table-column label="所属用户" prop="users"> </el-table-column>
        <el-table-column label="备注" prop="remark"> </el-table-column>
        <el-table-column label="操作人员" prop="actionusers"> </el-table-column>
        <el-table-column label="操作时间" prop="createtime" sortable>
        </el-table-column>
        <el-table-column label="操作渠道" prop="types">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.types === 1"> 支付宝 </el-tag>
            <el-tag v-else-if="scope.row.types === 2"> 微信 </el-tag>
            <el-tag v-else-if="scope.row.types === 3"> 银行卡 </el-tag>
            <el-tag v-else-if="scope.row.types === 4" type="warning">
              人工充值
            </el-tag>
            <el-tag v-else-if="scope.row.types === 5" type="danger">人工扣除</el-tag>
            <el-tag v-else-if="scope.row.types === 6">离岸充值</el-tag>
            <el-tag v-else-if="scope.row.types === 7">加密货币</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="充值金额" prop="money" sortable>
        </el-table-column>
        <el-table-column label="到账金额" prop="receipt" sortable>
        </el-table-column>
        <el-table-column label="充值币种" prop="montype" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.montype === 1" type="success"> CNY </el-tag>
            <el-tag v-else-if="scope.row.montype === 2" type="warning">
              USD
            </el-tag>
          </template>
        </el-table-column>
        <!-- 充值状态 -->
        <el-table-column prop="status" label="充值状态" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" type="success"> 成功 </el-tag>
            <el-tag v-else-if="scope.row.status === 1" type="warning">
              等待付款
            </el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="danger">
              失败
            </el-tag>
            <el-tag v-else-if="scope.row.status === 4" type="info">
              取消支付
            </el-tag>
            <el-tag v-else-if="scope.row.status === 5" type="danger">
              充值过期
            </el-tag>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" v-if="scope.row.status === 1" @click="closeOrder(scope.row)">取消支付
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination align="center" @size-change="userRecodhandleSizeChange"
        @current-change="userRecodhandleCurrentChange" :current-page="userRecodcurrentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="userRecodpageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="userRecodTotal">
      </el-pagination>
    </el-dialog>
    <!-- 卡充值弹窗 -->
    <el-dialog title="卡充值" custom-class="ls" :visible.sync="cardRechagedialogVisible" width="30%">
      <el-input v-model="searchCardRechageCardNo" placeholder="卡号" disabled></el-input>
      <br />
      <br />
      <el-input v-model="cardRechageNums" placeholder="充值金额(USD)"></el-input>
      <br />
      <br />
      <el-input v-model="cardRechageRmarks" placeholder="备注"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cardRechagedialogVisible = false">取 消</el-button>
        <el-button @click="(cardRechagedialogVisible = false), rechargeUser()" v-no-more-click>确
          定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="管理面板" custom-class="ls" :visible.sync="DialogMngPanel" width="90%">
      <el-table :data="userRecod" style="width: 100%" height="550" max-height="550" size="small" highlight-current-row>
        <el-table-column prop="nums" label="卡头">
          <template slot-scope="scope">
            <el-tag effect="dark">{{ scope.row.nums }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lv" label="拒付率">
          <template slot-scope="scope">
            <el-tag type="danger" effect="dark">{{ scope.row.lv }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="allnum" label="总交易笔数"> </el-table-column>
        <el-table-column prop="errnum" label="退款笔数"> </el-table-column>
        <el-table-column prop="renums" label="交易失败笔数"> </el-table-column>
        <el-table-column prop="allmoney" label="总消费金额"> </el-table-column>
        <el-table-column prop="renmoney" label="失败金额"> </el-table-column>
        <el-table-column prop="errmoney" label="退款金额"> </el-table-column>
        <el-table-column prop="updatetime" label="更新时间"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 卡号转出弹出框 -->
    <el-dialog custom-class="ls" v-dialogDrag title="卡号转出" :visible.sync="dialogZc" width="50%" ref="dialog__wrapper">
      <el-form :label-position="labelPosition" label-width="80px" :model="form_zc">
        <el-form-item label="转出卡号">
          <el-input v-model="form_zc.cardNo" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="卡号余额">
          <el-input v-model="form_zc.balance" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="转出金额">
          <el-input v-model="form_zc.zc_balance"></el-input>
        </el-form-item>
        <el-form-item label="转出备注">
          <el-input v-model="form_zc.zc_remarks"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <span slot="footer">
          <el-button @click="dialogZc = false">取 消</el-button>
          <el-button @click="cardZc()" v-no-more-click>确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 管理员安全策略 -->
    <el-dialog title="管理员安全策略" custom-class="ls" :visible.sync="glyDloag" width="30%">
      <el-input v-model="ips" style="margin-bottom: 20px"></el-input>
      <el-button @click="setIps()" v-no-more-click>确 定</el-button>
    </el-dialog>
    <!-- 管理员安全策略 -->
    <el-dialog title="修改身份信息" custom-class="ls" :visible.sync="SfzDiolag" width="30%">
      <el-input v-model="realname" placeholder="请输入姓名" style="margin-bottom: 20px"></el-input>
      <el-input v-model="realcard" placeholder="请输入身份证号码" style="margin-bottom: 20px"></el-input>
      <el-button @click="setEditCard()" v-no-more-click>确 定</el-button>
    </el-dialog>
    <!-- 解锁IP -->
    <el-dialog title="解锁IP限制" custom-class="ls" :visible.sync="DlogIps" width="30%">
      <el-input v-model="openIp" placeholder="请输入ip地址" style="margin-bottom: 20px"></el-input>
      <el-button @click="openiplock()" v-no-more-click>确 定</el-button>
    </el-dialog>
    <!-- 卡段配置 -->
    <el-dialog title="卡段配置" custom-class="ls" :visible.sync="carConfigModal" width="30%" @close="closeCarModal">
      <!-- <el-input
        v-model="carCofigInput"
        placeholder="请输入卡段(用逗号隔开)"
        style="margin-bottom: 20px"
      ></el-input> -->
      <div class="carcontainer">
        <!-- <div class="select_Card">
          <el-tag class="select_Card_item" @click="selectCH('JP')">机票</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('GG')">广告</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('DS')">电商</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('DY')">订阅</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('QT')">其他</el-tag>
        </div> -->
        <el-select v-model="carCofigInput" multiple placeholder="请选择卡段" @change="changeCardConfig">
          <el-option v-for="(d, index) in cardoptions" :key="index" :label="d" :value="d">
          </el-option>
        </el-select>
        <el-button style="width: 120px; margin-top: 20px" @click="setUserCarConfig" v-no-more-click>确
          定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
const lodash = require("lodash");
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      isShow: false,
      DlogIps: false,
      openIp: "",
      normal_total: "0",
      lock_total: "0",
      destroy_total: "0",
      ips: "127.0.0.1",
      glyDloag: false,
      usdisok: "1",
      rmbisok: "1",
      SfzDiolag: false,
      isthirdusd: "1",
      isgreduce: "1",
      labelPosition: "right",
      MngPanlForm: {
        is_card_openlv: "",
        opencardlv: "",
        is_card_uselv: "",
        service_char: "",
      },
      DialogMngPanel: false,
      form_zc: {
        id: "",
        balance: "0",
        zc_balance: "",
        cardNo: "",
        zc_remarks: "",
      },
      dialogZc: false,
      cardsRechageRecodeDataTotal: 0,
      recordDataTimes: [],
      formLabelWidth: "120px",
      rechageQd: "",
      userRecodTotal: 0,
      rechageStatus: "",
      czType: "1",
      realname: "",
      realcard: "",
      cardsTypes: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "锁卡",
        },
        {
          value: "3",
          label: "销卡",
        },
      ],
      czActionType: "1",
      EditUserform: {
        users: "",
        usdmoney: "",
        cnymoney: "",
        pusers: "",
        status: "",
        setcard: "",
        opencardlv: "",
        service_char: "",
        wx_char: "",
        is_card_uselv: "",
        is_card_openlv: "",
        gis_card_openlv: "",
        gopencardlv: "",
        gis_card_uselv: "",
        gservice_card: "",
        lognum: "",
        fenlv: "",
        usdfenlv: "",
        is_service_adv: "",
        service_adv: "",
        service_usdd: "",
        service_usdt: "", // 加密usdt 充值手续费
        wechat_qrcode: "", // 代理商二维码
        service_usddd: "",
        newPass: "",
        discount_lv:"",
        pservice_card: "",
        pis_card_uselv: "",
        popencardlv: "",
        pis_card_openlv: "",
        email: "",
        isreal: 1,
      },
      form_record: {},
      recordId: "",
      searchCardRechageCardNo: "", //  查询卡片充值卡号
      cardRechageNums: "", //  查询卡片充值金额
      tableData: [],
      cardRechagedialogVisible: false,
      dialogFormVisible: false,
      searchCardsFormVisible: false,
      consumptionFormVisible: false,
      modsname: "用户管理",
      searchKeys: "",
      userName: "",
      is_old:'', // 是否活跃
      cny: "",
      gshareusdmoney: "",
      pshareusdmoney: "",
      usd: "",
      dialogCzVisible: false,
      tel: "",
      bklv: '', // 设置全局最低汇率
      vloading: false,
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      remark: "", // 充值备注
      cxcardTotal: 0,
      czTypes: "",
      sfId: "",
      searchCardcurrentPage: 1, // 查询卡当前页码
      searchCardpageSize: 10, // 查询卡每页数据显示 条数
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      czJ: "0",
      ScreateTime: "",
      column: 5,
      CardRecodcurrentPage: 1, // 卡交易记录当前页码
      CardRecodpageSize: 5, // 卡交易记录每页数据显示 条数
      userRecodcurrentPage: 1, // 用户交易记录当前页码
      userRecodpageSize: 10, // 用户交易记录每页数据显示 条数
      selectVluesOrder: "",
      isopenusd: "1",
      isautodel: "1",
      tableTotal: 0,
      iplimit: "2", // 是否限制大陆ip
      login_type:'2', // 登录方式
      // 充值渠道
      qdOptions: [
        // {
        //   value: "1",
        //   label: "支付宝",
        // },
        // {
        //   value: "2",
        //   label: "微信",
        // },
        // {
        //   value: "3",
        //   label: "银行卡",
        // },
        {
          value: "4",
          label: "人工充值",
        },
        {
          value: "5",
          label: "人工扣除",
        },
        {
          value: "6",
          label: "离岸充值",
        },
        {
          value: "7",
          label: "加密货币",
        },
      ],
      // 充值状态
      orderStatus: [
        {
          value: "2",
          label: "充值成功",
        },
        {
          value: "3",
          label: "充值失败",
        },
        {
          value: "1",
          label: "等待充值",
        },
        {
          value: "4",
          label: "取消充值",
        },
        {
          value: "5",
          label: "充值过期",
        },
      ],
      superior: "",
      createTime: "",
      recharge_char: "",
      selectVlues: "",
      cnydraws: "0.00",
      usddraws: "0.00",
      czId: "",
      cardIds: "",
      selectTimes: "",
      selectUserTypeVal: "",
      cardsTypesVal: "",
      scCdIds: "",
      // 用户交易记录
      userRecod: [],
      // 卡交易记录表格数据
      cardsRechageRecodeData: [],
      // 查询卡片表格数据
      cardsData: [],
      cardRechageRmarks: "",
      // 查询卡片表单集合
      form_queryCards: {
        cardNo: "",
      },
      showuploadqrcode: false, // 上传二维码弹窗
      initLoading: true,
      selectUserType: [
        {
          value: 1,
          label: "普通用户",
        },
        {
          value: 2,
          label: "代理商",
        },
        {
          value: 3,
          label: "管理员",
        },
      ],
      options: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "停用",
        },
        {
          value: 3,
          label: "待审核",
        },
        {
          value: 4,
          label: "审核不通过",
        },
      ],
      carConfigModal: false, // 卡配置弹窗
      carCofigInput: "", // 卡段选择器
      cardoptions: [],
      fileList: [],
      TsDes: [
        {
          key: 'DECLINED INSUFFICIENT_FUNDS',
          value: '卡余额不足'
        },
        {
          key: 'DECLINED INVALID_CVC',
          value: '安全码错误'
        },
        {
          key: 'DECLINED LIFE_TIME_AMOUNT_EXCEEDED',
          value: '卡余额不足'
        },
        {
          key: 'DECLINED INVALID_EXPIRATION_DATE',
          value: '卡日期年月错误'
        },
        {
          key: 'DECLINED REASON_UNKNOWN',
          value: '未知错误'
        },
        {
          key: 'DECLINED NOT_ACTIVATED_CARD',
          value: '卡已失效'
        },
        {
          key: 'DECLINED Transaction not permitted to cardholder',
          value: '持卡人不准交易'
        },
        {
          key: 'DECLINED PLATFORM_SECURITY_VIOLATION',
          value: '风控拦截'
        },
        {
          key: 'DECLINED EXPIRED_CARD',
          value: '卡已失效'
        },
        {
          key: 'DECLINED Bad CVV2,CID,Expiry Date',
          value: '安全码错误'
        },
        {
          key: 'DECLINED MCC_BLOCKED',
          value: 'MCC错误'
        },
        {
          key: 'DECLINED Invalid Transaction(CVC1,CVV2,CID,iCVV Format Error)',
          value: '安全码错误'
        },
        {
          key: 'DECLINED Do not honour',
          value: '未激活'
        },
        {
          key: 'DECLINED Suspected Fraud',
          value: '涉嫌欺诈风险'
        },
        {
          key: 'DECLINED Transaction not Permitted to Cardholder',
          value: '持卡人不准交易'
        },
        {
          key: 'DECLINED COUNTRY_EXCLUDED',
          value: '拒绝的国家'
        },
      ]
    };
  },
  methods: {
    seleDesTop(des) {
      const result = this.TsDes.filter(item => item.key == des)
      if (result.length > 0) {
        return result[0].value
      } else {
        return des
      }
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    // 阿里上传oss
    handleUpload(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      put("/wechatQrcode/" + option.file.name, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/wechatQrcode/" + option.file.name).then((resSign) => {
          this.fileList.push({
            name: objName,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
        });
      });
    },
    uploadFile(file) {
      if (this.fileList.length == 1) {
        this.$message({
          type: "warning",
          message: "最多只能上传1张图片",
        });
        return;
      }
      return file;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 代理商上传二维码
    uploadQrcode(row) { },
    // 配置卡端
    selectCH(type) {
      if (type == "JP") {
        this.carCofigInput.push(
          ...[
            "532959",
            "515783",
            "491724",
            "486695",
            "223467",
            "428842",
            "493875",
          ]
        );
      } else if (type == "GG") {
        this.carCofigInput.push(
          ...["532959", "515783", "491724", "486695", "428842", "493875"]
        );
      } else if (type == "DS") {
        this.carCofigInput.push(...["532959"]);
      } else if (type == "DY") {
        this.carCofigInput.push(...["486695", "428842"]);
      } else if (type == "QT") {
        this.carCofigInput.push(...["486695", "491724"]);
      }
      let uniqueArray = [...new Set(this.carCofigInput)];
      this.carCofigInput = uniqueArray;
    },
    // 选择卡段
    changeCardConfig() {
      // console.log("val: ", this.carCofigInput);
    },
    // 查询所有卡段
    getCardList() {
      let params = {
        page: 1,
        page_size: 999,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getApplyProduct", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        const cards = res.data.data.data;
        const s = new Set();
        cards.forEach((item) => {
          s.add(item.cardarea);
        });
        // console.log("cards: ", cards);
        // const s = new Set(cards.map((item) => item.cardarea));
        this.cardoptions = [...s];
        // console.log("this.cardoptions: ", this.cardoptions);
      });
    },
    // 是否设置代理编辑权限
    limitProxy(row, index) {
      let params = {
        id: row.id,
        type: "3",
        agent_edit: index,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editAllowCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        console.log("res: ", res);
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.searchUser();
      });
    },
    // 是否开启加密货币充值
    limitRechargeUTChange(row, index) {
      let params = {
        id: row.id,
        type: "2",
        limit_utrecharge: index,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editAllowCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        console.log("res: ", res);
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.searchUser();
      });
    },
    // 设置用户登录方式
    setLoginType(row, index) {
      let params = {
        id: row.id,
        login_type: index,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/updatelogintype", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        console.log("res: ", res);
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.searchUser();
      });
    },
    // 是否更改汇付微信充值限制
    limitRechargeChange(row, index) {
      let params = {
        id: row.id,
        type: "1",
        limit_recharge: index,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editAllowCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        console.log("res: ", res);
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.searchUser();
      });
    },
    // 确定配置卡段
    setUserCarConfig() {
      let params = {
        id: this.scCdIds,
        type: "0",
        allow_cards: this.carCofigInput.join(","),
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editAllowCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        if (res.data.code == "200") {
          this.$message({
            message: "修改成功!",
            type: "success",
          }),
            (this.glyDloag = false);
        } else {
          this.$message({
            message: "修改失败!",
            type: "error",
          });
        }
        this.closeCarModal();
        this.searchUser();
      });
    },
    closeCarModal() {
      this.carCofigInput = "";
      this.scCdIds = "";
      this.carConfigModal = false;
    },
    // 点开配置弹窗
    openCarModal(row) {
      this.carCofigInput = row.allow_cards
        .split(",")
        .filter((card) => card.trim() !== "");
      this.scCdIds = row.id;
      this.carConfigModal = true;
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 设置管理员ip登录限制
    setIps() {
      let params = {
        iprule: this.ips,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setIprule", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "修改成功!",
              type: "success",
            }),
              (this.glyDloag = false);
          } else {
            this.$message({
              message: "修改失败!",
              type: "error",
            });
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 关闭编辑弹窗
    closeEditModal() {
      this.dialogFormVisible = false;
    },
    // 解锁ip
    openiplock() {
      let params = {
        ip: this.openIp,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/openiplock", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "解锁成功!",
              type: "success",
            }),
              (this.DlogIps = false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            }),
              (this.DlogIps = false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 查询流水记录
    searchRecod(index) {
      if (index >= 0) {
        this.recordId = index;
      }
      this.consumptionFormVisible = true;
      if (
        this.recordDataTimes[0] == undefined &&
        this.recordDataTimes[1] == undefined
      ) {
        this.recordDataTimes[0] = "";
        this.recordDataTimes[1] = "";
      }
      if (this.form_record.orderNo == undefined) {
        this.form_record.orderNo = "";
      }
      let params = {
        id: this.recordId,
        page: this.userRecodcurrentPage,
        limit: this.userRecodpageSize,
        order_num: this.form_record.orderNo,
        status: this.rechageStatus,
        types: this.rechageQd,
        start: this.recordDataTimes[0],
        ends: this.recordDataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getSignUserRedata", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.userRecod = res.data.data.data;
          this.userRecodTotal = res.data.data.total;
          for (let i = 0; i < this.userRecod.length; i++) {
            this.userRecod[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 查询用户开卡
    searchCards(index) {
      if (index == "rf") {
        this.form_queryCards.cardNo = "";
        this.cardsTypesVal = "";
        this.selectTimes = "";
      }
      if (index != undefined && index != "rf") {
        this.scCdIds = index.id;
      }
      this.searchCardsFormVisible = true;
      let params = {
        id: this.scCdIds,
        page: this.searchCardcurrentPage,
        limit: this.searchCardpageSize,
        card_num: this.form_queryCards.cardNo,
        statu: this.cardsTypesVal,
        createtime: this.selectTimes,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserCardData", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cardsData = res.data.data.data;
          this.cxcardTotal = res.data.data.total;
          for (let i = 0; i < this.cardsData.length; i++) {
            this.cardsData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            if (
              this.cardsData[i].card_type == "share" ||
              this.cardsData[i].card_type == "SHARE"
            ) {
              this.cardsData[i].ctype = "共享卡";
            } else {
              this.cardsData[i].ctype = "充值卡";
            }
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 编辑用户
    handleEdit: lodash.throttle(
      function (index, row) {
        console.log("row: ", row);
        // 设置上传二维码可见性
        if (row.types == "2" || row.types == "3") {
          this.showuploadqrcode = true;
        } else {
          this.showuploadqrcode = false;
        }
        let options = {
          id: index,
        };
        options = this.$lmjm(options);
        const result = this.$http.post("/api/v1/getEditUser", options, {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {

            if (res.data.code == 204) {
              this.$message.warning(res.data.msg)
            } else {
              this.EditUserform.id = res.data.data.id;
              this.EditUserform.users = res.data.data.users;
              this.EditUserform.createtime = this.getTime(
                res.data.data.createtime
              );
              this.EditUserform.phones = res.data.data.phones;
              this.EditUserform.pusers = res.data.data.pusers;
              this.EditUserform.status = res.data.data.status;
              this.EditUserform.usdmoney = row.usdmoney;
              this.EditUserform.cnymoney = row.cnymoney;
              this.EditUserform.setcard = res.data.data.setcard;
              this.EditUserform.service_char = res.data.data.service_card;
              this.EditUserform.wx_char = res.data.data.service_char;
              this.EditUserform.is_card_uselv = res.data.data.is_card_uselv;
              this.EditUserform.opencardlv = res.data.data.opencardlv;
              this.EditUserform.is_card_openlv = res.data.data.is_card_openlv;
              this.EditUserform.isreal = res.data.data.isreal;
              this.EditUserform.gopencardlv = res.data.data.gopencardlv;
              this.EditUserform.gis_card_openlv = res.data.data.gis_card_openlv;
              this.EditUserform.gis_card_uselv = res.data.data.gis_card_uselv;
              this.EditUserform.gservice_card = res.data.data.gservice_card;
              this.EditUserform.lognum = res.data.data.lognum;
              this.EditUserform.fenlv = res.data.data.fenlv;
              this.EditUserform.is_service_adv = res.data.data.is_service_adv;
              this.EditUserform.service_adv = res.data.data.service_adv;
              this.EditUserform.usdfenlv = res.data.data.usdfenlv;
              this.EditUserform.service_usdd = res.data.data.service_usdd;
              this.EditUserform.service_usdt = res.data.data.service_usdt;
              this.EditUserform.service_usddd = res.data.data.service_usddd;
              this.EditUserform.discount_lv = res.data.data.discount_lv;
              this.EditUserform.pservice_card = res.data.data.pservice_card;
              this.EditUserform.pis_card_uselv = res.data.data.pis_card_uselv;
              this.EditUserform.popencardlv = res.data.data.popencardlv;
              this.EditUserform.pis_card_openlv = res.data.data.pis_card_openlv;
              if (res.data.data.wechat_qrcode && res.data.data.wechat_qrcode !== " ") {
                let fileList = JSON.parse(res.data.data.wechat_qrcode);
                this.fileList = fileList;
              }else{
                this.fileList = [];
              }
              // 显示邮箱
              if (row.email) {
                this.EditUserform.email = row.email;
              } else {
                this.EditUserform.email = ""
              }
              this.dialogFormVisible = true;
            }
          },
          (error) => {
            console.log("错误:" + error);
          }
        );

      },
      1000,
      {
        trailing: false,
      }
    ),
    // 删除用户
    handleDelete(index, row) {
      this.open(index);
    },
    // 用户管理分页
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getUser();
    },
    // 清空
    qkcz() {
      this.form_record.orderNo = "";
      this.rechageQd = "";
      this.rechageStatus = "";
      this.recordDataTimes = "";
    },
    // 是否兑换美金
    isOpenusd() {
      let params = {
        isopenusd: this.isopenusd,
        usddraws: this.usddraws,
        bklv: this.bklv,
        cnydraws: this.cnydraws,
        isautodel: this.isautodel,
        rmbisok: this.rmbisok,
        usdisok: this.usdisok,
        iplimit: this.iplimit,
        login_type: this.login_type,
        isgreduce: this.isgreduce,
        isthirdusd: this.isthirdusd,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setCommonNum", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            return (
              this.$message({
                message: "修改成功!",
                type: "success",
              }),
              this.getCommonNum()
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getCommonNum() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCommonNum", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usddraws = String(res.data.data.usddraw);
          this.cnydraws = String(res.data.data.cnydraw);
          this.isopenusd = res.data.data.isopenusd;
          this.isautodel = res.data.data.isautodel;
          this.ips = res.data.data.iprule;
          this.rmbisok = res.data.data.rmbisok;
          this.usdisok = res.data.data.usdisok;
          this.isgreduce = res.data.data.isgreduce;
          this.isthirdusd = res.data.data.isthirdusd;
          this.cny = res.data.data.cnycount;
          this.usd = res.data.data.usdcount;
          this.bklv = res.data.data.bklv;
          this.pshareusdmoney = res.data.data.pshareusdmoney;
          this.gshareusdmoney = res.data.data.gshareusdmoney;
          this.iplimit = res.data.data.iplimit;
          this.login_type = res.data.data.login_type;
          this.initLoading = false
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 用户消费记录分页
    userRecodhandleCurrentChange(val) {
      this.userRecodcurrentPage = val;
      this.searchRecod();
    },
    // 用户消费记录分页
    userRecodhandleSizeChange(val) {
      this.userRecodcurrentPage = 1;
      this.userRecodpageSize = val;
      this.searchRecod();
    },
    // 查询卡分页
    searchCardhandleCurrentChange(val) {
      this.searchCardcurrentPage = val;
      this.searchCards();
    },
    // 查询卡分页
    searchCardhandleSizeChange(val) {
      this.searchCardcurrentPage = 1;
      this.searchCardpageSize = val;
      this.searchCards();
    },
    // 卡交易记录分页
    CardRecodhandleCurrentChange(val) {
      this.CardRecodcurrentPage = val;
      this.UserCarShoplist();
    },
    // 卡交易记录分页
    CardRecodhandleSizeChange(val) {
      this.CardRecodcurrentPage = 1;
      this.CardRecodpageSize = val;
      this.UserCarShoplist();
    },
    // 卡交易记录查询
    UserCarShoplist(row) {
      if (row != undefined) {
        this.cardIds = row.id;
      }
      let params = {
        page: this.CardRecodcurrentPage,
        limit: this.CardRecodpageSize,
        card_id: this.cardIds,
        id: this.scCdIds,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserCarShoplist", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cardsRechageRecodeData = res.data.data.data;
          this.cardsRechageRecodeDataTotal = res.data.data.total;
          for (let i = 0; i < this.cardsRechageRecodeData.length; i++) {
            this.cardsRechageRecodeData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 用户管理分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUser();
    },
    filterTag(value, row) {
      return row.status === value;
    },
    // 搜索用户信息
    searchUser() {
      if (this.userName == "limao") {
        this.isShow = true;
        return;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        users: this.userName,
        phones: this.tel,
        types: this.selectUserTypeVal,
        status: this.selectVlues,
        createtime: this.ScreateTime,
        is_old: this.is_old,
      };
      params = this.$lmjm(params);
      this.vloading = true
      const result = this.$http.post("/api/v1/getUsersdata", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.tableTotal = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            this.tableData[i].updatetime = this.getTime(
              res.data.data.data[i].updatetime
            );
          }
          this.vloading = false
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 获取用户信息
    getUser() {
      this.selectUserTypeVal = "";
      this.userName = "";
      this.tel = "";
      this.isShow = false;
      this.selectVlues = "";
      this.selectVlues = "";
      this.ScreateTime = "";
      let options = {
        page: this.currentPage,
        limit: this.pageSize,
        users: "",
        phones: "",
        types: "",
        status: "",
        createtime: "",
      };
      options = this.$lmjm(options);
      this.vloading = true
      const result = this.$http.post("/api/v1/getUsersdata", options, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.tableTotal = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            this.tableData[i].updatetime = this.getTime(
              res.data.data.data[i].updatetime
            );
          }
          this.vloading = false
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 获取修改的用户状态
    changeStatus(val) {
      this.EditUserform.status = val;
      this.$forceUpdate();
    },
    // 获取已开卡数量
    getCardStatistic(rf) {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCardStatistic", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.normal_total = res.data.data.normal_total;
            this.lock_total = res.data.data.lock_total;
            this.destroy_total = res.data.data.destroy_total;
          }
          if (rf == "rf") {
            this.$message.success(res.data.msg);
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 修改用户
    processUser() {
      let params = {
        id: this.EditUserform.id,
        pass: this.EditUserform.newPass,
        setcard: this.EditUserform.setcard,
        service_char: this.EditUserform.wx_char,
        service_card: this.EditUserform.service_char,
        status: this.EditUserform.status,
        is_card_openlv: this.EditUserform.is_card_openlv,
        opencardlv: this.EditUserform.opencardlv,
        is_card_uselv: this.EditUserform.is_card_uselv,
        isreal: this.EditUserform.isreal,
        gopencardlv: this.EditUserform.gopencardlv,
        gis_card_openlv: this.EditUserform.gis_card_openlv,
        gis_card_uselv: this.EditUserform.gis_card_uselv,
        gservice_card: this.EditUserform.gservice_card,
        lognum: this.EditUserform.lognum,
        fenlv: this.EditUserform.fenlv,
        service_adv: this.EditUserform.service_adv,
        usdfenlv: this.EditUserform.usdfenlv,
        is_service_adv: this.EditUserform.is_service_adv,
        service_usdd: this.EditUserform.service_usdd,
        service_usdt: this.EditUserform.service_usdt,
        service_usddd: this.EditUserform.service_usddd,
        discount_lv:this.EditUserform.discount_lv,
        pservice_card: this.EditUserform.pservice_card,
        pis_card_uselv: this.EditUserform.pis_card_uselv,
        popencardlv: this.EditUserform.popencardlv,
        pis_card_openlv: this.EditUserform.pis_card_openlv,
        email: this.EditUserform.email,
      };
      if (this.fileList.length > 0) {
        params.wechat_qrcode = JSON.stringify(this.fileList);
      }
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editUserdata", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            return (
              this.$message({
                message: "修改成功!",
                type: "success",
              }),
              (this.dialogFormVisible = false),
              (this.EditUserform.newPass = ""),
              this.searchUser()
            );
          }
          this.$message.error(res.data.msg);
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    open(ids) {
      this.$confirm("是否删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: ids,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/delUserinfo", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                return (
                  this.$message({
                    message: "删除成功!",
                    type: "success",
                  }),
                  this.searchUser()
                );
              }
              this.$message.error(res.data.msg);
            },
            (error) => {
              console.log("错误:" + error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 卡充值显示
    rechargeUserShow(row) {
      if (row.status == "1") {
        this.cardRechagedialogVisible = true;
        this.searchCardRechageCardNo = row.num;
        this.cardIds = row.id;
        this.getHl();
      } else {
        this.$message({
          message: "此卡状态无法充值!",
          type: "error",
        });
      }
    },
    // 获取卡充值手续费
    getHl() {
      let params = {
        id: this.cardIds,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserCardDeRe", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.recharge_char = res.data.data.recharge_char / 100;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // // 卡充值
    // rechargeUser() {
    //   let params = {
    //     card_id: this.cardIds,
    //     id: this.scCdIds,
    //     money: this.cardRechageNums,
    //     endmoney: this.cardRechageNums,
    //     remark: this.cardRechageRmarks
    //   }
    //   var result = this.$http.post("/api/v1/setUserCardRecharge", params, {
    //     headers: {
    //       "token": window.localStorage.getItem('token')
    //     }
    //   })
    //   result.then((res) => {
    //     if (res.data.code == "200") {
    //       this.$message({
    //         message: '充值成功!',
    //         type: 'success'
    //       });
    //       this.searchCards();
    //     } else {
    //       this.$message({
    //         message: res.data.msg,
    //         type: 'error'
    //       });
    //     }
    //   }, (error) => {
    //     console.log(error)
    //   })
    // },
    // 解卡
    jk(row) {
      let params = {
        card_id: row.id,
        id: this.scCdIds,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setUserunLockCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "解卡成功!",
              type: "success",
            }),
              this.searchCards();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 锁卡
    LockCard(row) {
      let params = {
        card_id: row.id,
        id: this.scCdIds,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setUserLockCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "锁卡成功!",
              type: "success",
            }),
              this.searchCards();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 销卡
    delUserCard(row) {
      this.$confirm("是否销卡?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.cardshop == "3") {
            if (row.usdmoney > 0 && row.card_type == "normal") {
              this.$message({
                type: "error",
                message: "请清空卡内余额再进行销卡！",
              });
              return;
            }
          }
          let params = {
            id: row.id,
            uid: this.scCdIds,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/delUserCard", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                this.$message({
                  message: "销卡成功!",
                  type: "success",
                }),
                  this.searchCards();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消",
          });
        });
    },
    
    // 设为代理
    addProxy(ids) {
      this.$confirm("是否设为代理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: ids,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/setagent", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              console.log(res);
              if (res.data.code == "200") {
                return (
                  this.$message({
                    message: "设置成功!",
                    type: "success",
                  }),
                  this.searchUser()
                );
              }
              this.$message.error(res.data.msg);
            },
            (error) => {
              console.log("错误:" + error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消",
          });
        });
    },
    // 取消代理
    delProxy(ids) {
      this.$confirm("是否取消代理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: ids,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/cancelagent", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              console.log(res);
              if (res.data.code == "200") {
                return (
                  this.$message({
                    message: "取消成功!",
                    type: "success",
                  }),
                  this.searchUser()
                );
              }
              this.$message.error(res.data.msg);
            },
            (error) => {
              console.log("错误:" + error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消",
          });
        });
    },
    // 充值
    RecodCz(ids, types) {
      if (types == "cny") {
        this.czTypes = "cny";
        this.czType = "1";
      } else if (types == "usd") {
        this.czTypes = "usd";
        this.czType = "2";
      }
      this.dialogCzVisible = true;
      if (this.czActionType == "2") {
        if (ids >= 0) {
          this.czId = ids;
        } else {
          let params = {
            id: this.czId,
            type: this.czType,
            money: this.czJ,
            remark: this.remark,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/reducemoney", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                return (
                  this.$message({
                    message: "充值成功!",
                    type: "success",
                  }),
                  (this.dialogCzVisible = false),
                  this.searchUser()
                );
              }
            },
            (error) => {
              console.log("错误:" + error);
            }
          );
        }
      } else {
        if (ids >= 0) {
          this.czId = ids;
        } else {
          let params = {
            id: this.czId,
            type: this.czType,
            money: this.czJ,
            remark: this.remark,
          };
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/setbalance", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                return (
                  this.$message({
                    message: "充值成功!",
                    type: "success",
                  }),
                  (this.dialogCzVisible = false),
                  this.searchUser()
                );
              }
            },
            (error) => {
              console.log("错误:" + error);
            }
          );
        }
      }
    },
    // 取消支付
    closeOrder(row) {
      console.log(this.recordId);
      let params = {
        id: row.id,
        user_id: this.recordId,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/CancelUserp", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message.success("取消订单成功!");
            this.searchRecod();
          } else {
            this.$message.error("操作失败，请重试!");
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    getUserRecord(index) {
      let params = {
        id: index,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserRecord", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            console.log(res);
            this.userRecod = res.data.data.data;
            for (let i = 0; i < this.userRecod.length; i++) {
              this.userRecod[i].updatetime = this.getTime(
                res.data.data.data[i].updatetime
              );
            }
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 同步卡余额
    SyncMoney(row) {
      let params = {
        card_id: row.id,
        id: this.scCdIds,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setUserSyncMoney", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "同步成功!",
              type: "success",
            }),
              this.searchCards();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 卡转出
    transferCard(item) {
      this.cardshop = item.cardshop;
      this.dialogZc = true;
      this.form_zc.cardNo = item.num;
      let params = {
        id: item.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getManNewCardUsd", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.form_zc.balance = res.data.data.usdmoney;
          this.form_zc.id = item.id;
        },
        (error) => {
          console.log(error);
        }
      );
    }, // 卡转出
    cardZc() {
      if (this.cardshop == "2") {
        if (this.form_zc.balance - this.form_zc.zc_balance < 1) {
          this.$message({
            message:
              "你需要在卡上至少保留1美元。如果您不再使用该卡，您可以销卡，我们将把卡上的所有资金退还给您。",
            type: "error",
          });
          return;
        }
      }
      let params = {
        id: this.form_zc.id,
        money: this.form_zc.zc_balance,
        remark: this.form_zc.zc_remarks,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setManCardRoOu", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "转出成功!",
              type: "success",
            });
            this.searchCards();
            this.dialogZc = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取用户身份信息
    getEditReal(row) {
      let params = {
        id: row,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getEditReal", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.SfzDiolag = true;
            this.realname = res.data.data.realname;
            this.realcard = res.data.data.realcard;
            this.sfId = row;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 修改用户身份信息
    setEditCard() {
      let params = {
        id: this.sfId,
        realname: this.realname,
        realcard: this.realcard,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setEditCard", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.SfzDiolag = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {
    this.getCardList();
    this.getUser();
    this.getCommonNum();
    this.getCardStatistic();
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
.carswitch_span {
  margin-left: 12px;
}

.username {
  width: 10%;
}

.bklvInput {
  margin-left: 12px;
  width: 140px !important;
}

.el-input,
.el-select {
  margin-right: 1%;
}

.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-form {
  margin-bottom: 20px;
}

.pagesRecod {
  margin-bottom: 20px;
}

.carcontainer {
  display: flex;
  flex-direction: column;
}

.select_Card {
  margin-bottom: 25px;
}

.select_Card_item {
  cursor: pointer;
  margin-right: 10px;
}
</style>
