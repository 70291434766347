<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-form :model="form_carTrade" ref="form_carTrade" :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-input v-model="form_carTrade.cardNo" prefix-icon="el-icon-tickets" placeholder="请输入卡号">
              </el-input>
              <!-- 操作事件 -->
              <el-select v-model="selelctRechargeStatusVal" placeholder="请选择操作事件" clearable>
                <el-option v-for="item in selelctRechargeStatus" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- 交易状态 -->
              <el-select v-model="statusVal" placeholder="请选择交易状态" clearable>
                <el-option v-for="item in selectStatusVal" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button v-no-more-click icon="el-icon-search" @click="getCardDetails()">搜 索</el-button>
              <el-button v-no-more-click icon="el-icon-refresh" @click="getCardDetails('1')">刷 新
              </el-button>
              <el-button style="margin-bottom: 20px" icon="el-icon-download" @click="batchExports()">
                下载
              </el-button>
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table @selection-change="handleSelectionChange" :data="tableData" v-loading="vLoading"
              style="width: 100%" height="620" max-height="800" size="small" highlight-current-row>
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="用户名" width="140" prop="users" sortable :sort-method="userSort">
              </el-table-column>
              <el-table-column label="操作事件" width="130">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.event_type === '6810'" effect="dark" type="success">
                    消费授权(通过)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6890'" effect="dark" type="danger">
                    消费授权(拒绝)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6510'" effect="dark" type="success">
                    退款授权(通过)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6590'" effect="dark" type="danger">
                    退款授权(拒绝)
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6930'" effect="dark" type="success">
                    消费授权冲正
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === '6940'" effect="dark" type="danger">
                    退款授权冲正
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'auth'" effect="dark" type="success">
                    消费
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'corrective_auth'" effect="dark" type="success">
                    纠正授权
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'verification'" effect="dark" type="success">
                    验证
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'void'" effect="dark" type="warning">
                    撤销
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'refund'" effect="dark" type="warning">
                    退款
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'corrective_refund'" effect="dark" type="danger">
                    效正退款
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'recharge'" effect="dark" type="success">
                    充值
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'recharge_return'" effect="dark" type="success">
                    卡余额退还
                  </el-tag>
                  <el-tag v-else-if="
                    scope.row.event_type === 'discard_recharge_return'
                  " effect="dark" type="warning">
                    销卡退回
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'service_fee'" effect="dark" type="success">
                    服务费
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'refund_reversal'" effect="dark" type="danger">
                    退款撤销
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'Auth'" effect="dark" type="success">
                    授权消费
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'Reversal'" effect="dark" type="warning">
                    退款
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'card_out'" effect="dark" type="warning">
                    卡转出
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'card_in'" effect="dark" type="warning">
                    卡转入
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="卡号" prop="card_num" width="160">
              </el-table-column>
              <el-table-column label="商户名" prop="shopname" width="350">
              </el-table-column>
              <el-table-column label="交易时间" prop="createtime" width="160">
              </el-table-column>
              <el-table-column label="交易金额" width="100" sortable :sort-method="moneySort" prop="money">
              </el-table-column>
              <el-table-column label="交易币种" width="80" prop="moneytype">
              </el-table-column>
              <el-table-column label="交易状态" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.statu === 1" type="success">
                    交易成功
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 2" type="danger">
                    交易失败
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 3" type="warning">
                    交易中
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 4" type="success">
                    交易撤销成功
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 5" type="danger">
                    交易撤销失败
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="交易描述" prop="desct">
                <template slot-scope="scope">
                  {{ seleDesTop(scope.row.desct) }}
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000, 1500]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totals">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { dataConversionUtil } from "../../plugins/excel.js";
export default {
  data() {
    return {
      multipleSelection: [],
      totals: 0,
      name: "卡交易记录",
      selelctRechargeStatusVal: "",
      statusVal: "",
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      labelPosition: "right",
      tableData: [],
      vLoading: false,
      form_carTrade: {
        cardNo: "",
      },
      selectStatusVal: [
        {
          value: "1",
          label: "交易成功",
        },
        {
          value: "2",
          label: "交易失败",
        },
        // {
        //   value: "3",
        //   label: "交易中",
        // },
        // {
        //   value: "4",
        //   label: "交易撤销成功",
        // },
        // {
        //   value: "5",
        //   label: "交易撤销失败",
        // },
      ],
      // 支付状态
      selelctRechargeStatus: [
        // {
        //   value: "6810",
        //   label: "消费授权(通过)",
        // },
        // {
        //   value: "6890",
        //   label: "消费授权(拒绝)",
        // },
        // {
        //   value: "6510",
        //   label: "退款授权(通过)",
        // },
        // {
        //   value: "6590",
        //   label: "退款授权(拒绝)",
        // },
        // {
        //   value: "6930",
        //   label: "消费授权冲正",
        // },
        // {
        //   value: "6940",
        //   label: "退款授权冲正",
        // },
        // {
        //   value: "auth",
        //   label: "消费",
        // },
        // {
        //   value: "corrective_auth",
        //   label: "纠正授权",
        // },
        // {
        //   value: "verification",
        //   label: "验证",
        // },
        // {
        //   value: "void",
        //   label: "撤销",
        // },
        // {
        //   value: "refund",
        //   label: "退款",
        // },
        // {
        //   value: "corrective_refund",
        //   label: "效正退款",
        // },
        // {
        //   value: "recharge",
        //   label: "充值",
        // },
        // {
        //   value: "recharge_return",
        //   label: "卡余额退还",
        // },
        // {
        //   value: "discard_recharge_return",
        //   label: "销卡退回",
        // },
        // {
        //   value: "service_fee",
        //   label: "服务费",
        // },
        // {
        //   value: "refund_reversal",
        //   label: "退款撤销",
        // },
        {
          value: "Auth",
          label: "授权消费",
        },
        {
          value: "Reversal",
          label: "退款",
        },
        {
          value: "card_out",
          label: "卡转出",
        },
        {
          value: "card_in",
          label: "卡转入",
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          // 最近一年
          {
            text: '最近一年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear() - 1);
              picker.$emit('pick', [start, end]);
            }
          },
          // 最近三年
          {
            text: '最近三年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear() - 3);
              picker.$emit('pick', [start, end]);
            }
          }
        ],
      },
      TsDes: [
        {
          key: 'DECLINED INSUFFICIENT_FUNDS',
          value: '卡余额不足'
        },
        {
          key: 'DECLINED INVALID_CVC',
          value: '安全码错误'
        },
        {
          key: 'DECLINED LIFE_TIME_AMOUNT_EXCEEDED',
          value: '卡余额不足'
        },
        {
          key: 'DECLINED INVALID_EXPIRATION_DATE',
          value: '卡日期年月错误'
        },
        {
          key: 'DECLINED REASON_UNKNOWN',
          value: '未知错误'
        },
        {
          key: 'DECLINED NOT_ACTIVATED_CARD',
          value: '卡已失效'
        },
        {
          key: 'DECLINED Transaction not permitted to cardholder',
          value: '持卡人不准交易'
        },
        {
          key: 'DECLINED PLATFORM_SECURITY_VIOLATION',
          value: '风控拦截'
        },
        {
          key: 'DECLINED EXPIRED_CARD',
          value: '卡已失效'
        },
        {
          key: 'DECLINED Bad CVV2,CID,Expiry Date',
          value: '安全码错误'
        },
        {
          key: 'DECLINED MCC_BLOCKED',
          value: 'MCC错误'
        },
        {
          key: 'DECLINED Invalid Transaction(CVC1,CVV2,CID,iCVV Format Error)',
          value: '安全码错误'
        },
        {
          key: 'DECLINED Do not honour',
          value: '未激活'
        },
        {
          key: 'DECLINED Suspected Fraud',
          value: '涉嫌欺诈风险'
        },
        {
          key: 'DECLINED Transaction not Permitted to Cardholder',
          value: '持卡人不准交易'
        },
        {
          key: 'DECLINED COUNTRY_EXCLUDED',
          value: '拒绝的国家'
        },
      ]
    };
  },
  methods: {
    seleDesTop(des) {
      const result = this.TsDes.filter(item => item.key == des)
      if (result.length > 0) {
        return result[0].value
      } else {
        return des
      }
    },
    // 金额排序
    moneySort(a, b) {
      const floatA = parseFloat(a.money);
      const floatB = parseFloat(b.money);
      return floatA - floatB;
    },
    // 金额排序
    userSort(a, b) {
      const floatA = parseFloat(a.users);
      const floatB = parseFloat(b.users);
      return floatA - floatB;
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getCardDetails();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCardDetails();
    },
    // 获取消费记录
    getCardDetails(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.form_carTrade.cardNo = "";
        this.selelctRechargeStatusVal = "";
        this.statusVal = "";
        this.dataTimes = ["", ""];
      }
      if (!this.dataTimes) {
        this.dataTimes = ["", ""];
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        cardnum: this.form_carTrade.cardNo,
        eventype: this.selelctRechargeStatusVal,
        statu: this.statusVal,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      this.vLoading = true
      const result = this.$http.post("api/v1/getAllUserShopRecord", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log(res);
          this.tableData = res.data.data.data;
          this.totals = Number(res.data.data.total);
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
          this.vLoading = false
        },
        (error) => {
          console.log(error);
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    batchExports() {
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择要导出的数据');
      }
      const tableHeader = [
        [
          "用户名",
          "操作事件",
          "卡号",
          "商户名",
          "交易时间",
          "交易金额",
          "交易币种",
          "交易状态",
          "交易描述",
        ],
      ];
      const dataList = [];
      // 将导出数据存放在this.mumultipleSelection中 ,遍历将要导出的数据
      this.multipleSelection.forEach((item, index) => {
        let eventTye = "";
        switch (item.event_type) {
          case "6810":
            eventTye = "消费授权(通过)";
            break;
          case "6890":
            eventTye = "消费授权(拒绝)";
            break;
          case "6510":
            eventTye = "退款授权(通过)";
            break;
          case "6590":
            eventTye = "退款授权(拒绝)";
            break;
          case "6930":
            eventTye = "消费授权冲正";
            break;
          case "6940":
            eventTye = "消费授权(通过)";
            break;
          case "auth":
            eventTye = "消费";
            break;
          case "corrective_auth":
            eventTye = "纠正授权";
            break;
          case "verification":
            eventTye = "验证";
            break;
          case "void":
            eventTye = "撤销";
            break;
          case "refund":
            eventTye = "退款";
            break;
          case "corrective_refund":
            eventTye = "效正退款";
            break;
          case "recharge":
            eventTye = "充值";
            break;
          case "recharge_return":
            eventTye = "卡余额退还";
            break;
          case "discard_recharge_return":
            eventTye = "销卡退回";
            break;
          case "service_fee":
            eventTye = "服务费";
            break;
          case "refund_reversal":
            eventTye = "退款撤销";
            break;
          case "discard_recharge_return":
            eventTye = "销卡退回";
            break;
          case "Auth":
            eventTye = "授权消费";
            break;
          case "Reversal":
            eventTye = "退款";
            break;
          case "card_out":
            eventTye = "卡转出";
            break;
          case "card_in":
            eventTye = "卡转入";
            break;
        }
        dataList.push([
          item.users,
          eventTye,
          item.card_num,
          item.shopname,
          item.createtime,
          item.money,
          item.moneytype,
          item.statu == "1" ? "成功" : "失败",
          item.desct,
        ]);
      });
      dataConversionUtil.dataToExcel("导出列表名", tableHeader, dataList);
      this.$message.success("导出成功!");
    },
  },
  created() {
    this.getCardDetails();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}
</style>
