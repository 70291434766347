<template>
  <el-main style="overflow-y: auto;">
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>{{ $t("gg.home") }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("userAuth.title") }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="menu-container">
        <!-- 语言 -->
        <div class="svg-box mr">
          <el-dropdown trigger="hover" style="float: right">
            <span class="el-dropdown-link">
              <svg
                t="1731672005757"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6711"
                data-spm-anchor-id="a313x.search_index.0.i13.5bb33a81qvpsBb"
                width="20"
                height="20"
              >
                <path
                  d="M165.302857 405.357714h62.756572c1.755429 0 3.437714 0.731429 4.681142 1.974857a7.168 7.168 0 0 1 0 9.801143L124.342857 530.285714a9.654857 9.654857 0 0 1-14.043428 0L1.974857 417.133714A7.094857 7.094857 0 0 1 0 412.233143c0-3.803429 2.925714-6.875429 6.582857-6.875429h70.217143a448.146286 448.146286 0 0 1 870.4 0h-88.502857a362.788571 362.788571 0 0 0-693.394286 0z m693.394286 213.284572h-62.756572a6.802286 6.802286 0 0 1-6.582857-6.875429c0-1.901714 0.731429-3.657143 1.901715-4.900571L899.657143 493.714286a9.654857 9.654857 0 0 1 14.043428 0l108.324572 113.152a7.168 7.168 0 0 1 0 9.801143 6.509714 6.509714 0 0 1-4.681143 1.974857h-70.144a448.146286 448.146286 0 0 1-870.4 0h88.502857a362.788571 362.788571 0 0 0 693.394286 0zM469.796571 320h85.577143l148.626286 384H619.300571l-47.542857-105.984H449.682286l-43.739429 105.984H320l149.796571-384zM471.771429 512h79.945142l-39.058285-107.52L471.771429 512z"
                  fill="#71717a"
                  p-id="6712"
                ></path>
              </svg>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix">
                <div class="noticeList" @click="langChange('cn')">中文简体</div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <div class="noticeList" @click="langChange('en')">Enghlish</div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <div class="noticeList" @click="langChange('hk')">繁體中文</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 客服 -->
        <div class="svg-box mr aniSvg" @click="qrCodeOpneModal">
          <svg t="1731672093991" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9984" width="20" height="20"><path d="M896 426.666667a384 384 0 1 0-768 0h85.333333a298.666667 298.666667 0 1 1 597.333334 0h85.333333zM896 938.666667a384 384 0 1 0-768 0 42.666667 42.666667 0 0 0 85.333333 0 298.666667 298.666667 0 1 1 597.333334 0 42.666667 42.666667 0 0 0 85.333333 0z" p-id="9985" fill="#71717a"></path><path d="M512 640a213.333333 213.333333 0 1 0 0-426.666667 213.333333 213.333333 0 0 0 0 426.666667z m0-85.333333a128 128 0 1 1 0-256 128 128 0 0 1 0 256zM789.333333 362.666667v120.32a64 64 0 0 0 128 0V362.666667a64 64 0 1 0-128 0zM106.666667 341.333333v141.653334a64 64 0 0 0 128 0V341.333333a64 64 0 0 0-128 0z" p-id="9986" fill="#71717a"></path></svg>
        </div>
        <!-- 退出 -->
        <div class="svg-box mr " @click="logout">
          <svg
            t="1736163311176"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6720"
            width="18"
            height="20"
          >
            <path
              d="M511.991851 1023.982934c-282.320581 0-511.991381-229.679334-511.991382-511.991382 0-282.277915 229.670801-511.991381 511.991382-511.991381a510.813801 510.813801 0 0 1 344.271538 133.032427 42.640349 42.640349 0 0 1 2.901284 60.244319c-15.829067 17.493039-42.793946 18.730351-60.286985 2.901285A425.840298 425.840298 0 0 0 511.991851 85.332068c-235.26004 0-426.659485 191.399445-426.659485 426.659484 0 235.302706 191.399445 426.659485 426.659485 426.659485 105.982216 0 207.569839-39.082009 286.032518-110.078147a42.606216 42.606216 0 0 1 60.244319 2.986616 42.640349 42.640349 0 0 1-2.986616 60.244319c-94.163748 85.246565-216.103029 132.179108-343.290221 132.179109"
              fill="#71717a"
              p-id="6721"
            ></path>
            <path
              d="M885.975955 552.447404H341.328057c-23.466272 0-42.665948-19.199677-42.665948-42.665948s19.199677-42.665948 42.665948-42.665948h544.647898L792.989787 371.449918a42.77688 42.77688 0 0 1 0-60.329651 42.77688 42.77688 0 0 1 60.329651 0l158.205337 162.770593a42.58915 42.58915 0 0 1 12.356059 32.946646 42.77688 42.77688 0 0 1 0 5.879367 42.58915 42.58915 0 0 1-12.356059 32.955179L853.319438 708.442645a42.77688 42.77688 0 0 1-60.329651 0 42.77688 42.77688 0 0 1 0-60.329651l92.986168-95.674123z"
              fill="#71717a"
              p-id="6722"
            ></path>
          </svg>
        </div>
        
      </div>
    </div>

    <!-- 搜索框 -->
    <div class="auth_form">
      <!-- title -->
      <div class="user_title">{{ $t("userAuth.t1") }}</div>
      <template v-if="isreal == '1' && realname">
        <!-- 人脸识别 -->
        <div class="user_form_userFace">
          <div class="user_form_title">{{ $t("userAuth.t2") }}</div>
          <div class="user_form_content">
            <span>{{ $t("userAuth.t3") }}</span>
            <div class="user_form_content_qrUrl" ref="qrCodeUrl"></div>
          </div>
        </div>
      </template>
      <template v-if="isreal == '3'">
        <!-- 审核 -->
        <div class="user_form_shenhe">
          <!-- <div class="user_form_title">
                                        提示
                                    </div> -->
          <div class="user_form_content">
            <svg
              t="1736054695198"
              class="icon"
              viewBox="0 0 1349 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4295"
              width="128"
              height="128"
            >
              <path
                d="M489.529781 300.137931c14.124138 0 26.482759 12.358621 26.482758 26.482759V564.965517h326.62069c14.124138 0 26.482759 12.358621 26.482758 26.482759s-12.358621 26.482759-26.482758 26.482758h-353.103448c-14.124138 0-26.482759-12.358621-26.482759-26.482758v-264.827586c0-14.124138 12.358621-26.482759 26.482759-26.482759z"
                fill="#ED9A21"
                p-id="4296"
              ></path>
              <path
                d="M551.322884 1024c-98.868966 0-195.972414-28.248276-278.951724-82.97931-12.358621-8.827586-15.889655-24.717241-7.062069-37.075862 8.827586-12.358621 24.717241-15.889655 37.075862-7.062069C374.77116 944.551724 461.281505 971.034483 551.322884 971.034483c252.468966 0 459.034483-206.565517 459.034483-459.034483S803.79185 52.965517 551.322884 52.965517 92.288401 259.531034 92.288401 512c0 51.2 8.827586 100.634483 24.717242 148.303448 5.296552 14.124138-1.765517 28.248276-15.889656 33.544828-14.124138 5.296552-28.248276-1.765517-33.544827-15.889655-17.655172-52.965517-28.248276-109.462069-28.248276-165.958621C39.322884 229.517241 268.840125 0 551.322884 0S1063.322884 229.517241 1063.322884 512 833.805643 1024 551.322884 1024z"
                fill="#1C2C70"
                p-id="4297"
              ></path>
            </svg>
            <span>{{ $t("userAuth.t4") }}</span>
          </div>
        </div>
      </template>
      <template v-if="isreal == '1' && !realname">
        <div v-if="!rejectRemark">
          <!-- 类型 -->
          <div class="user_form_radio" v-if="!(isreal == '1' && realname)">
            <div class="user_form_title">
              {{ $t("userAuth.form3") }}
            </div>
            <div class="user_form_content">
              <el-radio
                v-model="user_Form.type"
                :label="1"
                border
                size="medium"
                >{{ $t("userAuth.form1") }}</el-radio
              >
              <el-radio
                v-model="user_Form.type"
                :label="2"
                border
                size="medium"
                >{{ $t("userAuth.form2") }}</el-radio
              >
            </div>
          </div>
          <!-- 姓名 -->
          <div class="user_form" v-if="user_Form.type == 1">
            <div class="user_form_title">
              {{ $t("userAuth.form4") }}
            </div>
            <div class="user_form_content">
              <el-input
                style="width: 100%; height: 38px"
                v-model="user_Form.realname"
                :placeholder="$t('userAuth.place1')"
              ></el-input>
            </div>
          </div>
          <!-- 身份证 -->
          <div class="user_form" v-if="user_Form.type == 1">
            <div class="user_form_title">
              {{ $t("userAuth.form5") }}
            </div>
            <div class="user_form_content">
              <el-input
                style="width: 100%; height: 38px"
                v-model="user_Form.realcard"
                :placeholder="$t('userAuth.place2')"
              ></el-input>
            </div>
          </div>

          <!-- 国家 -->
          <div class="user_form" v-if="user_Form.type == 2">
            <div class="user_form_title">
              {{ $t("userAuth.form6") }}
            </div>
            <el-select
              v-model="user_Form.country"
              filterable
              style="width: 100%"
              :placeholder="$t('userAuth.place3')"
              size="medium"
            >
              <el-option
                :label="d.name"
                :value="d.name"
                v-for="(d, index) in allCountry"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <!-- 护照姓名 -->
          <div class="user_form" v-if="user_Form.type == 2">
            <div class="user_form_title">
              {{ $t("userAuth.form7") }}
            </div>
            <div class="user_form_content">
              <el-input
                style="width: 100%; height: 38px"
                v-model="user_Form.name"
                :placeholder="$t('userAuth.place4')"
              ></el-input>
            </div>
          </div>
          <!-- 护照号码 -->
          <div class="user_form" v-if="user_Form.type == 2">
            <div class="user_form_title">
              {{ $t("userAuth.form8") }}
            </div>
            <div class="user_form_content">
              <el-input
                style="width: 100%; height: 38px"
                v-model="user_Form.number"
                :placeholder="$t('userAuth.place5')"
              ></el-input>
            </div>
          </div>
          <!-- 护照 -->
          <div class="user_form_file" v-if="user_Form.type == 2">
            <div class="user_form_title">
              {{ $t("userAuth.form9") }}
            </div>
            <div class="user_form_content">
              <el-upload
                class="upload-demo"
                accept="image/jpeg, image/png, image/jpg"
                drag
                :http-request="handleUpload"
                :limit="1"
                :before-upload="uploadFile"
                :file-list="fileList"
                action="#"
                :on-remove="handleRemove"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{ $t("userAuth.tip7") }}
                </div>
                <div class="el-upload__tip" slot="tip">
                  {{ $t("userAuth.tip8") }}
                </div>
              </el-upload>
            </div>
          </div>
          <!-- 护照实例 -->
          <div class="user_form_test" v-if="user_Form.type == 2">
            <div class="user_form_title">{{ $t("userAuth.tip9") }}</div>
            <div class="user_form_iamge">
              <span>{{ $t("userAuth.tip10") }}</span>
              <img src="@/images/shili.png" alt="" />
            </div>
          </div>
        </div>
        <div v-else>
          <!-- 审核 -->
          <div class="user_form_shenhe">
            <div class="user_form_content">
              <svg
                t="1736055299521"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="10518"
                width="64"
                height="64"
              >
                <path
                  d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m0-981.333333C252.8 42.666667 42.666667 252.8 42.666667 512s210.133333 469.333333 469.333333 469.333333 469.333333-210.133333 469.333333-469.333333S771.2 42.666667 512 42.666667z m277.674667 713.472C758.954667 652.8 631.125333 597.333333 512 597.333333c-124.16 0-256 64-282.112 158.890667-2.346667 8.533333-15.786667 12.842667-26.496 9.770667a16.853333 16.853333 0 0 1-12.373333-20.949334C220.885333 620.032 376.746667 554.666667 512 554.666667c134.101333 0 288.298667 65.408 318.208 196.181333 1.706667 7.381333-5.546667 14.378667-14.933333 16.512-11.008 2.474667-23.04-2.517333-25.6-11.221333zM704 469.333333c-35.328 0-64-38.186667-64-85.333333s28.672-85.333333 64-85.333333 64 38.186667 64 85.333333-28.672 85.333333-64 85.333333z m-384 0C284.672 469.333333 256 431.146667 256 384s28.672-85.333333 64-85.333333S384 336.853333 384 384s-28.672 85.333333-64 85.333333z"
                  fill="#FF524F"
                  p-id="10519"
                ></path>
              </svg>
              <span>{{ rejectRemark }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- 按钮 -->
      <div class="user_form_btn">
        <div
          class="user_form_btn_content"
          @click="userAuth"
          v-if="isreal == '1' && !realname && !rejectRemark"
        >
          {{ $t("userAuth.comfirm") }}
        </div>
        <div
          class="user_form_btn_content"
          @click="AuthNext"
          v-if="rejectRemark"
        >
          {{ $t("userAuth.reset") }}
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="jldlog" width="20%" @close="handleClose">
      <div style="text-align: center" >
        <div style="margin-bottom: 20px; font-size: 20px">
          {{ $t("welcome.customerServiceWeChat") }}
        </div>
        <img :src="wechatUrl" width="200px" />
        <div>{{ $t("welcome.working") }}</div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import QRCode from "qrcodejs2";
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
import countrys from "@/utils/country.json";
export default {
  data() {
    return {
      user_Form: {
        type: 1,
        country: "", // 国家
        realcard: "", // 身份证号
        realname: "", // 身份证姓名
        passport: "", //   护照图片
        name: "", // 护照姓名
        number: "", // 护照号码
      }, // 实名认知表单
      allCountry: countrys, // 国家列表
      websock: "", // websocket对象
      resetsinter: "", // 重连定时器
      reconnectnum: "",
      isreal: "",
      realname: "",
      rejectRemark: "",
      fileList: [],
      jldlog:false, // 弹窗
      wechatUrl: require('../images/wx_zxkf.jpg'),
    };
  },
  created() {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src =
      "https://cn-shanghai-aliyun-cloudauth.oss-cn-shanghai.aliyuncs.com/web_sdk_js/jsvm_all.js";
    document.body.appendChild(script);
    console.log(
      "%c [ document ]-318",
      "font-size:13px; background:pink; color:#bf2c9f;",
      document
    );
    // this.initWebSocket();
    // 实时获取实名认证结果
    // this.setImitateMess();

    // function add_chatinline() {
    //   const hccid = 44569780;
    //   const nt = document.createElement("script");
    //   nt.async = true;
    //   nt.src = "https://mylivechat.com/chatinline.aspx?hccid=" + hccid;
    //   const ct = document.getElementsByTagName("script")[0];
    //   ct.parentNode.insertBefore(nt, ct);
    // }
    // add_chatinline();
    this.initWebSocket();
    // 实时获取实名认证结果
    this.setImitateMess()
  },
  beforeDestroy(){
    this.websock.close();
  },
  mounted() {
    this.getuserinfos();
  },
  methods: {
    goHome(){
      this.$router.push({ path: "/welcome" });
    },
    handleClose() {
      this.jldlog = false;
    },
    qrCodeOpneModal(){
      this.jldlog = true;
    },
    logout() {
      window.localStorage.clear();
      this.$router.push({ path: "/login" });
    },
    //语言切换
    langChange(e) {
      localStorage.setItem("lang", e);
      this.$i18n.locale = e;
      window.location.reload();
    },
    // 重新审核
    AuthNext() {
      this.rejectRemark = "";
      this.realname = ''
      this.isreal = '1'
    },
    // 设置实名信息
    async setUserRealAuth() {
      let params = this.user_Form;
      if (this.user_Form.type == 2) {
        if (
          !this.user_Form.name ||
          !this.user_Form.number ||
          !this.user_Form.country
        ) {
          this.$message.error(this.$t("userAuth.tip1"));
          return;
        }
        if (this.fileList.length > 0) {
          params.passport = JSON.stringify(this.fileList);
        } else {
          this.$message.error(this.$t("userAuth.tip2"));
          return;
        }
        let Regex = /^[A-Z]+$/;
        let NoRegex = /^[A-Z0-9]+$/;
        if (!this.user_Form.name || Regex.test(this.user_Form.name) == false) {
          this.$message.error(this.$t("userAuth.tip5"));
          return;
        }
        if (
          !this.user_Form.number ||
          NoRegex.test(this.user_Form.number) == false
        ) {
          this.$message.error(this.$t("userAuth.tip6"));
          return;
        }
      }
      if (this.user_Form.type == 1) {
        let nameRegex = /^[\u4e00-\u9fa5]{2,4}$/;
        let idcardRegex =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if (
          !this.user_Form.realname ||
          nameRegex.test(this.user_Form.realname) == false
        ) {
          this.$message.error(this.$t("userAuth.tip3"));
          return;
        }
        if (
          !this.user_Form.realcard ||
          idcardRegex.test(this.user_Form.realcard) == false
        ) {
          this.$message.error(this.$t("userAuth.tip4"));
          return;
        }
      }
      params = this.$lmjm(params);
      const result = await this.$http.post("/api/v1/fixrealinfo", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      return result;
    },
    // 用户实名信息
    async userAuth() {
      const result = await this.setUserRealAuth();
      // 大陆用户
      if (result.data.code == 200) {
        let params = {};
        params = this.$lmjm(params);
        const res = await this.$http.post("/api/v1/getuserinfos", params, {
          headers: {
            token: window.localStorage.getItem("token"),
          },
        });
        if (res.data.code == 200) {
          const userinfo = res.data.data;
          this.isreal = userinfo.isreal;
          this.realname = userinfo.realname;
          if (this.isreal == 1) {
            this.getUserAuthRejectMsg().then((res) => {
              this.rejectRemark = res.data.data.remark;
              // this.smrzDialog = true;
            });

            this.setRealPage();
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } else {
        this.$message.error(result.data.msg);
      }
    },
    initWebSocket() {
      // 初始化weosocket
      const token = window.localStorage.getItem("token");
      const wsuri = "wss://" + this.$domain + "/wss?token=" + token;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      const actions = {
        type: "login",
        client_name: "yy",
        room_id: "1",
      };
      this.websocketsend(JSON.stringify(actions));
      this.resetsinter = setInterval(this.heartbeat, 10000);
    },
    heartbeat() {
      const clock = {
        type: "pong",
      };
      this.websocketsend(JSON.stringify(clock));
    },
    websocketsend(Data) {
      if(this.websock.readyState == 1){
        this.websock.send(Data);
      }
      // 数据发送
    },
    websocketonerror(e) {
      // 连接建立失败重连
      this.errorhandle(e);
    },
    websocketclose(e) {
      // 关闭
      this.errorhandle(e);
    },
    errorhandle(e) {
      // 连接建立失败，或者连接被关闭，全都到这儿来
      this.reconnectnum++;
      // 允许重连3次，不然这里会无限重连，给服务器带来非常大的压力。
      if (this.reconnectnum < 3) {
        if (this.resetsinter) {
          clearInterval(this.resetsinter);
          this.resetsinter = null;
          this.websock.close();
        }
        this.initWebSocket();
      } else {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
        // 关闭重连

        // this.websock.close()
      }
    },
    websocketonmessage(e) {
      // 数据接收
      const data = JSON.parse(e.data);
      switch (data.type) {
        // 服务端ping客户端
        case "firstping":
          const ty = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(ty));
          this.$cookie.set("client_id", data.client_id);
          break;
        case "ping":
          const clock = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(clock));
          break;
        case "pingsuccess":
          break;
        case "loginsuccess":
          break;
        case "wxpay":
          if (data.statu == 2) {
            this.$message({
              message: "支付成功!",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$message({
              message: "支付失败!",
              type: "error",
              duration: 5000,
            });
          }
          break;
        case "real":
          if (data.statu == 2) {
            this.$message({
              message: "实名认证效验通过!",
              type: "success",
            });
            this.goHome()
          } else {
            this.$message({
              message: "实名认证效验不通过，和注册身份证信息不对应!",
              type: "error",
            });
          }
          break;
        case "downcard":
          if (data.statu == 2) {
          }
          break;
        case "hfpay":
          if (data.statu == 2) {
            this.$message({
              message: "支付成功!",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$message({
              message: "支付失败!",
              type: "error",
              duration: 5000,
            });
          }
          break;
        case "gopencard":
          this.refreshcon();
          break;
        case "opencard":
          this.refreshcon();
          break;
      }
    },
    refreshcon() {
      // 后端如果向前端发送了websocket，则重新开始计时，过30秒后再发心跳 。
      if (this.resetsinter) {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
      }
      this.resetsinter = setInterval(this.heartbeat, 10000);
      this.reconnectnum = 0;
    },
    // 获取实名驳回信息
    async getUserAuthRejectMsg() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getHwrealaudit", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      return result;
    },
    // 获取实名认证二维码
    setRealPage() {
      this.info = JSON.stringify(getMetaInfo()).toString();
      if (this.$cookie.get("client_id").length > 0) {
        this.client_id = this.$cookie.get("client_id");
      }
      let params = {
        type: "1",
        info: this.info.toString(),
        client_id: this.client_id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setRealPage", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.smrzurl = res.data.data.url;
            new QRCode(this.$refs.qrCodeUrl, {
              text: res.data.data.source_url, // 二维码的内容
              width: 480,
              height: 400,
              colorDark: "#000",
              colorLight: "#fff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          } else if(res.data.code == "204") {
              this.$message({
                message:
                  "实名认证信息异常，请核实您的姓名与身份证号码或联系管理员!",
                type: "error",
              });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取实名认证结果
    setImitateMess() {
      let params = {
        types: "1",
        client_id: this.client_id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setImitateMess", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => { },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取个人中心数据
    getuserinfos() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getuserinfos", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.isreal = res.data.data.isreal;
          this.realname = res.data.data.realname;
          // this.isreal = 1
          // this.realname = ''
          if (this.isreal == "1") {
            this.getUserAuthRejectMsg().then((res) => {
              this.rejectRemark = res.data.data.remark;
              // this.smrzDialog = true;
            });

            this.setRealPage();
            this.initWebSocket();
          } else if (this.isreal == "3") {
            // this.smrzDialog = true;
          }
          // this.smrzDialog = true;
          //   this.initWebSocket();
          //   this.setRealPage();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    uploadFile(file) {
      if (this.fileList.length == 1) {
        this.$message({
          type: "warning",
          message: "最多只能上传1张图片",
        });
        return;
      }
      return file;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    // 阿里上传oss
    handleUpload(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      put("/userAuth/" + option.file.name, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/userAuth/" + option.file.name).then((resSign) => {
          this.fileList.push({
            name: objName,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gg_img_box {
  width: 100%;
  height: auto;
}

.gg_content {
  font-size: 0.5em;
  margin-left: 18%;
  margin-top: 5%;
}

.gg_title {
  margin-left: 18%;
  margin-top: 5%;
}

.auth_form {
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 1.5em;
  font-weight: 540;
}

.user_title {
  text-align: center;
  width: 480px;
}

.user_form {
  margin-top: 30px;
  width: 480px;
  height: 73px;

  .user_form_title {
    color: #000;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.title_tab {
  display: flex;
}

.menu-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .aniSvg {
    animation: boundImg 800ms linear infinite;
  }

  .svg-box {
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 25px;
    z-index: 9999;
    display: flex;
    justify-content: center;
  }

  .calendar_box {
    position: absolute;
    width: auto;
    height: auto;
    transform: translateX(-90%);
    border-radius: 8px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .svg-box:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mr {
    margin-right: 10px;
  }
}

.user_form_radio {
  margin-top: 30px;
  width: 480px;
  height: 73px;

  .user_form_title {
    color: #000;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.user_form_file {
  margin-top: 30px;
  width: 480px;
  height: 270px;

  .user_form_title {
    color: #000;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.user_form_test {
  margin-top: 30px;
  width: 480px;
  height: 500px;

  .user_form_title {
    color: #000;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .user_form_iamge {
    width: 100%;
    height: 400px;

    span {
      margin-top: 12px;
      width: 100%;
      color: rgba(89, 140, 149, 1);
      font-size: 13px;
      font-weight: 500;
    }

    img {
      margin-top: 12px;
      width: 200px;
      height: 400px;
    }
  }
}

.user_form_btn {
  margin-top: 50px;
  width: 480px;
  height: 73px;

  .user_form_btn_content {
    cursor: pointer;
    width: 100%;
    height: 50px;
    background-color: #27272a;
    color: #fff;
    line-height: 50px;
    text-align: center;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
}

.user_form_shenhe {
  margin-top: 50px;
  width: 480px;
  height: 150px;

  .user_form_content {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.user_form_userFace {
  margin-top: 30px;
  width: 480px;
  height: 500px;

  .user_form_title {
    color: #000;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .user_form_content {
    height: 400px;

    span {
      margin-top: 12px;
      width: 100%;
      color: rgba(89, 140, 149, 1);
      font-size: 13px;
      font-weight: 500;
    }

    .user_form_content_qrUrl {
      margin-top: 12px;
      width: 100%;
      height: 400px;
      // border: 1px red solid;
    }
  }
}
</style>
