<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('applyCard.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('applyCard.cardManagement') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('applyCard.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-row :gutter="20">
              <!-- 输入卡号 -->
              <el-input style="
                  width: 15%;
                  margin-right: 20px;
                  margin-bottom: 20px;
                  float: right;
                " v-model="card_nums" :placeholder="$t('applyCard.des1')">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="getCards"></i>
              </el-input>
              <!-- 申请卡片表格 -->
              <el-table :data="cardData" v-loading="vLoading" style="width: 100%; margin-top: 20px" height="620"
                max-height="620" size="small" highlight-current-row>
                <!-- id -->
                <el-table-column prop="id" label="id" v-if="false">
                </el-table-column>
                <!-- 卡编号 -->
                <el-table-column prop="nums" :label="$t('applyCard.cardStatus')"> </el-table-column>
                <!-- 卡号段 -->
                <el-table-column prop="cardarea" :label="$t('applyCard.cardNumberSegment')">
                </el-table-column>
                <!-- 卡组 -->
                <el-table-column prop="organ" :label="$t('applyCard.cardDecks')">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.organ === null" type="info">
                      无
                    </el-tag>
                    <!-- <span> {{ scope.row.organ }} </span> -->
                    <div class="table_svg_box">
                      <img class="table_svg" src="@/images/svg/applycard/discover.svg"
                        v-if="scope.row.organ == 'Discover'" alt="" srcset="" />
                      <img class="table_svg" src="@/images/svg/applycard/mastercard.svg"
                        v-if="scope.row.organ == 'MasterCard'" alt="" srcset="" />
                      <img class="table_svg" src="@/images/svg/applycard/Visa.svg" v-if="scope.row.organ == 'VISA'"
                        alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <!-- 所属地区 -->
                <el-table-column prop="card_add" :label="$t('applyCard.region')">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.card_add === null" type="info">
                      无
                    </el-tag>
                    <span> {{ scope.row.card_add }} </span>
                  </template>
                </el-table-column>
                <!-- 卡币种 -->
                <el-table-column prop="moneytype" :label="$t('applyCard.cardCurrency')">
                  <template slot-scope="scope">
                    <el-tag> {{ scope.row.moneytype }} </el-tag>
                  </template>
                </el-table-column>
                <!-- 开卡功能 -->
                <el-table-column prop="isaddcard" :label="$t('applyCard.customizeYourBillingAddress')" width="115"
                  align="center">
                  <template slot-scope="scope">
                    <div class="flexcenter">
                      <div class="ico_box success" v-if="scope.row.isbank === 1">
                        <img src="@/images/svg/applycard/success.svg" alt="" srcset="" />
                      </div>
                      <div class="ico_box error" v-if="scope.row.isbank === 2">
                        <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <!-- 开卡功能 -->
                <el-table-column prop="isaddcard" :label="$t('applyCard.signUpFunction')" width="80">
                  <template slot-scope="scope">
                    <div class="ico_box success" v-if="scope.row.isaddcard === 1">
                      <img src="@/images/svg/applycard/success.svg" alt="" srcset="" />
                    </div>
                    <div class="ico_box error" v-else-if="scope.row.isaddcard === 2">
                      <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <!-- 充值功能 -->
                <el-table-column prop="isrechar" :label="$t('applyCard.rechargeFunction')" width="80">
                  <template slot-scope="scope">
                    <div class="ico_box success" v-if="scope.row.isrechar === 1">
                      <img src="@/images/svg/applycard/success.svg" alt="" srcset="" />
                    </div>
                    <div class="ico_box error" v-else-if="scope.row.isrechar === 2">
                      <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <!-- 转出功能 -->
                <el-table-column prop="istoslide" :label="$t('applyCard.rolloutFunction')" width="80">
                  <template slot-scope="scope">
                    <div class="ico_box success" v-if="scope.row.istoslide === 1">
                      <img src="@/images/svg/applycard/success.svg" alt="" srcset="" />
                    </div>
                    <div class="ico_box error" v-else-if="scope.row.istoslide === 2">
                      <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <!-- 销卡功能 -->
                <el-table-column prop="isopenremove" :label="$t('applyCard.cancelTheCardFunction')" width="80">
                  <template slot-scope="scope">
                    <div class="ico_box success" v-if="scope.row.isopenremove === 1">
                      <img src="@/images/svg/applycard/success.svg" alt="" srcset="" />
                    </div>
                    <div class="ico_box error" v-else-if="scope.row.isopenremove === 2">
                      <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <!-- 冻结功能 -->
                <el-table-column :label="$t('applyCard.freezeFunction')" width="80" prop="waytype">
                  <template slot-scope="scope">
                    <div class="ico_box success" v-if="scope.row.isdisables === 1">
                      <img src="@/images/svg/applycard/success.svg" alt="" srcset="" />
                    </div>
                    <div class="ico_box error" v-else-if="scope.row.isdisables === 2">
                      <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                    </div>
                    <div class="ico_box error" v-else-if="scope.row.isdisables === null">
                      <img src="@/images/svg/applycard/error.svg" alt="" srcset="" />
                    </div>
                  </template>
                </el-table-column>
                <!-- 开卡数量 -->
                <!-- <el-table-column prop="cardnum" :label="$t('applyCard.numberOfCardsOpened')">
                </el-table-column> -->
                <!-- 开卡费 -->
                <!-- <el-table-column prop="service_char" :label="$t('applyCard.signUpFee')">
                  <template slot-scope="scope">
                    {{ scope.row.service_char }}
                    <el-tooltip class="item" effect="dark" :content="$t('applyCard.des2')" placement="top">
                      <i v-if="scope.row.service_char === '999.00'" style="fontSize: 14px; marginLeft: 5px"
                        class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </template>
                </el-table-column> -->
                <!-- 卡充值手续费 -->
                <!-- <el-table-column prop="recharge_char" :label="$t('applyCard.des3')" width="110"> -->
                <!-- </el-table-column> -->
                <!-- 卡描述 -->
                <el-table-column prop="descs" :label="$t('applyCard.cardDescription')" :show-overflow-tooltip="true">
                </el-table-column>
                <!-- 操作 -->
                <el-table-column :label="$t('applyCard.operation')" align="center" width="180" :filters="[
                  { text: this.$t('applyCard.yes'), value: true },
                  { text: this.$t('applyCard.notAvailable'), value: false },
                ]" :filter-method="filterapplycard">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="$t('applyCard.des4')" placement="top">
                      <button class="applybtn applybtn_dis" :disabled="!scope.row.allowCard" v-if="
                        !scope.row.allowCard && scope.row.isaddcard == '1'
                      ">
                        {{ $t('applyCard.apply') }}
                      </button>
                    </el-tooltip>
                    <button class="applybtn" v-if="scope.row.allowCard && scope.row.isaddcard == '1'"
                      @click="applyCards(scope.row)">
                      {{ $t('applyCard.apply') }}
                    </button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination align="center" @size-change="userRecodhandleSizeChange"
                @current-change="userRecodhandleCurrentChange" :current-page="userRecodcurrentPage"
                :page-sizes="[30, 50, 100]" :page-size="userRecodpageSize" layout="total, prev, pager, next"
                :total="userRecodTotal">
              </el-pagination>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 申请卡片弹出框 -->
    <el-dialog v-dialogDrag :title="$t('applyCard.applyForACard')" custom-class="ls" @close="closeDialog"
      :visible.sync="dialogVisible" width="50%" ref="dialog__wrapper" top="5%">
      <div class="dialog-body">
        <div class="card-info">
          <el-descriptions :column="2" border :title="$t('applyCard.cardInfo')">
            <!-- 卡编号 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FcardNumber') }}
              </template>
              {{ form_queryCards.nums }}
            </el-descriptions-item>
            <!-- 卡号段 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FcardNumberSegment') }}
              </template>
              {{ form_queryCards.card_bin }}
            </el-descriptions-item>
            <!-- 有效期 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FexpirationDate') }}
              </template>
              {{ form_queryCards.endyear }}
            </el-descriptions-item>
            <!-- 卡币种 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FcardCurrency') }}
              </template>
              {{ form_queryCards.moneytype }}
            </el-descriptions-item>
            <!-- 卡描述 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FcardDescription') }}
              </template>
              <el-tooltip class="item" effect="dark" :content="form_queryCards.descs" placement="top">
                <span
                  style="cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 100px;display: inline-block;">
                  {{ form_queryCards.descs }}
                </span>
              </el-tooltip>
            </el-descriptions-item>
            <!-- 开卡费 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FsignUpFee') }}
              </template>
              $ &nbsp;{{ form_queryCards.service_char }}
            </el-descriptions-item>
            <!-- 余额(USD) -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FbalanceUsd') }}
              </template>
              $ &nbsp;{{ usd }}
            </el-descriptions-item>
            <!-- 合计支出 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FtotalSpending') }}
              </template>
              <el-tag>$ &nbsp;{{ form_queryCards.spend }}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="card-apply">
          <el-descriptions :column="2" border :title="$t('applyCard.cardapplyNumber')">
            <!-- 充值金额 -->
            <el-descriptions-item v-if="waytype == '2' || types == 'share'">
              <template slot="label">
                {{ $t('applyCard.FtopUpAmount') }}
              </template>
              <el-input-number controls-position="right" v-model="rechargeAmount" @change="handleChange(form_queryCards)" :precision="2"
                :min="1" :label="$t('applyCard.FtopUpAmount')">
              </el-input-number>
            </el-descriptions-item>
            <!-- 申请数量 -->
            <el-descriptions-item>
              <template slot="label">
                {{ $t('applyCard.FnumberOfApplications') }}
              </template>
              <el-input-number controls-position="right" v-model="nums" @change="handleChange(form_queryCards)" :min="1" :max="50"
                :label="$t('applyCard.FnumberOfApplications')"></el-input-number>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">{{ $t('applyCard.Fcancel') }}</el-button>
        <el-tooltip v-if="isTs === true" class="item" effect="dark" :content="$t('applyCard.Fdes5')" placement="top">
          <el-button v-no-more-click @click="openCards()">{{ $t('applyCard.Fconfirm') }}</el-button>
        </el-tooltip>
        <el-button v-if="isTs === false" v-no-more-click @click="openCards()">{{
          $t('applyCard.Fconfirm')
        }}</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import BigNumber from "bignumber.js";

import { Loading } from "element-ui";
import { bignumber, number } from "mathjs";
const lodash = require("lodash");
export default {
  data() {
    return {
      card_nums: "",
      userRecodTotal: 0,
      userRecodcurrentPage: 1, // 用户交易记录当前页码
      userRecodpageSize: 30, // 用户交易记录每页数据显示 条数
      isTs: false,
      name: "申请卡片",
      dialogVisible: false,
      formLabelWidth: "120px",
      zd_form: {
        births: "",
        emails: "",
        first_name: "",
        last_name: "",
        mobile: "",
        mobile_prefix: "",
        nationality_country_code: "",
        residential_address: "",
        residential_city: "",
        residential_postal_code: "",
        residential_state: "",
      },
      cardTypes: "",
      fullscreenLoading: false,
      diolog_zd: false,
      lods: "",
      nums: 1,
      rechargeAmount: 0.0,
      waytype: "",
      types: "",
      successCardNums: 0,
      cardData: [],
      vLoading: false,
      form_queryCards: {
        spend: "",
      },
      labelPosition: "right",
      form_queryCardsRules: {},
      cny: "0",
      usd: "0",
      resetsinter: null,
      reconnectnum: 0,
      allow_cards: [],
    };
  },
  methods: {
    // 卡片过滤
    filterapplycard(value, row) {
      return row.allowCard === value;
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    }, // 用户消费记录分页
    userRecodhandleCurrentChange(val) {
      this.userRecodcurrentPage = val;
      this.getCards();
    },
    // 用户消费记录分页
    userRecodhandleSizeChange(val) {
      this.userRecodpageSize = val;
      this.getCards();
    },
    // 初始化卡信息

    // 申请卡片获取汇率回调接口
    applyCards(row) {
      console.log(row);
      this.waytype = row.waytype;
      this.types = row.types;

      this.getCards();
      this.form_queryCards = row;
      let params = {
        id: row.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getApplyCardInfo", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (row.waytype == "2") {
            this.form_queryCards.service_char = res.data.data.gopencardlv;
            this.form_queryCards.recharge_char = res.data.data.gservice_card;
            if (row.types == "share") {
              this.rechargeAmount = 1.0;
            } else if (row.types == "recharge") {
              this.rechargeAmount = 20.0;
            }
          } else if (row.waytype == "1") {
            this.form_queryCards.service_char = res.data.data.opencardlv;
            this.form_queryCards.recharge_char = res.data.data.recharge_char;
          } else if (row.waytype == "3") {
            this.form_queryCards.service_char = row.service_char;
            this.form_queryCards.recharge_char = row.recharge_char;
            if (row.types == "share") {
              this.rechargeAmount = 1.0;
            }
          }

          this.form_queryCards.moneytype = res.data.data.types;
          this.usd = res.data.data.wallet;
          this.cardTypes = row.waytype;
          this.handleChange(row);
          this.dialogVisible = true;
          if (row.waytype == "2" && row.types == "share") {
            this.isTs = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 计算金额
    handleChange(scop) {
      console.log('%c [ scop ]-606', 'font-size:13px; background:pink; color:#bf2c9f;', scop)
      if (this.form_queryCards.recharge_char > 0) {
        // 充值汇率大于0 余额费用 = (开卡费 * 开卡数量 ) + (充值金额 * 开卡数量) + ((充值金额 * 开卡数量) * (充值汇率 / 100))
        // 充值汇率等于0 余额费用 = (开卡费 * 开卡数量) + (充值金额 *开卡数量)

        if ((this.cardTypes = "2")) {
          // 开卡费 + 充值金额 + 手续费
          // this.form_queryCards.service_char 开卡费
          // this.rechargeAmount 充值金额
          // this.form_queryCards.recharge_char 充值手续费
          // this.nums 开卡数量
          const service_char = BigNumber(this.form_queryCards.service_char)
            .plus(this.rechargeAmount)
            .toString();
          const ssf = this.form_queryCards.recharge_char / 100;
          const ssf_1 = BigNumber(ssf);
          const sxf = ssf_1.div(0.995).toString();

          const sxf_1 = BigNumber(sxf).times(this.rechargeAmount).toString();

          const sum = BigNumber(service_char).plus(sxf_1).toFixed(2);

          const jg = BigNumber(sum).times(this.nums).toString();
          if (jg.indexOf(".") > 0) {
            this.form_queryCards.spend = jg.substring(0, jg.indexOf(".") + 3);
          } else {
            this.form_queryCards.spend = jg;
          }
        } else if ((this.cardTypes = "3")) {
          // 计算开卡费 = 开卡费 * 开卡数量
          let pkkf = "";
          const pservice_char = BigNumber(this.form_queryCards.service_char);
          pkkf = pservice_char.multipliedBy(this.nums).toString();

          if (pkkf.indexOf(".") > 0) {
            this.form_queryCards.spend = pkkf.substring(
              0,
              pkkf.indexOf(".") + 3
            );
          } else {
            this.form_queryCards.spend = pkkf;
          }
        }
      } else {
        if (this.types == "recharge" && scop.waytype == 2) {
          this.rechargeAmount = 20.0;
        }
        // 计算开卡费 = 开卡费 * 开卡数量
        let pkkf = "";
        const pservice_char = BigNumber(this.form_queryCards.service_char);
        pkkf = pservice_char.multipliedBy(this.nums).toString();

        if (pkkf.indexOf(".") > 0) {
          this.form_queryCards.spend = pkkf.substring(0, pkkf.indexOf(".") + 3);
        } else {
          this.form_queryCards.spend = pkkf;
        }

        let czf = this.rechargeAmount * this.nums;
        this.form_queryCards.spend = number(this.form_queryCards.spend) + czf;
      }
    },
    // 回显账单信息
    getBill() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getBill", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.zd_form.births = res.data.data.births;
            this.zd_form.emails = res.data.data.emails;
            this.zd_form.first_name = res.data.data.first_name;
            this.zd_form.last_name = res.data.data.last_name;
            this.zd_form.mobile = res.data.data.mobile;
            this.zd_form.mobile_prefix = res.data.data.mobile_prefix;
            this.zd_form.nationality_country_code =
              res.data.data.nationality_country_code;
            this.zd_form.residential_address =
              res.data.data.residential_address;
            this.zd_form.residential_city = res.data.data.residential_city;
            this.zd_form.residential_country_code =
              res.data.data.residential_country_code;
            this.zd_form.thisresidential_postal_code =
              res.data.data.residential_postal_code;
            this.zd_form.residential_state = res.data.data.residential_state;
            this.diolog_zd = true;
          } else {
            this.$message({
              message: "获取账单信息失败!",
              type: "error",
            });
            this.diolog_zd = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 设置账单信息
    setBill() {
      let params = {
        births: this.zd_form.births,
        emails: this.zd_form.emails,
        first_name: this.zd_form.first_name,
        last_name: this.zd_form.last_name,
        mobile: this.zd_form.mobile,
        mobile_prefix: this.zd_form.mobile_prefix,
        nationality_country_code: this.zd_form.nationality_country_code,
        residential_address: this.zd_form.residential_address,
        residential_city: this.zd_form.residential_city,
        residential_country_code: this.zd_form.nationality_country_code,
        residential_postal_code: this.zd_form.residential_postal_code,
        residential_state: this.zd_form.residential_state,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setBill", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            console.log(res);
            this.$message({
              message: "设置账单信息成功!",
              type: "success",
            });
            this.diolog_zd = false;
          } else {
            console.log(res);
            this.$message({
              message: "设置账单信息失败!" + res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取卡
    getCards(ty) {
      if (!ty) {
        this.card_nums = "";
        this.userRecodpageSize = 30;
        this.userRecodcurrentPage = 1;
        this.card_nums = "";
      }
      let params = {
        page: this.userRecodcurrentPage,
        page_size: this.userRecodpageSize,
        cardarea: this.card_nums,
      };
      params = this.$lmjm(params);
      this.vLoading = true;
      const result = this.$http.post("/api/v1/getApplyProduct", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cardData = res.data.data.data;
          this.userRecodTotal = res.data.data.total;
          for (let i = 0; i < this.cardData.length; i++) {
            const item = this.cardData[i];
            if (this.allow_cards.includes(item.cardarea)) {
              item.allowCard = true;
            } else {
              item.allowCard = false;
            }
          }
          this.vLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取用户余额
    getUserInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getuserinfos", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cny = res.data.data.cny;
          this.usd = res.data.data.usd;
          this.allow_cards = res.data.data.allow_cards.split(",");
          this.getCards();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 开卡
    openCards: lodash.throttle(
      function () {
        if (this.waytype == "1") {
          const loading = this.$loading({
            lock: true,
            text: "开卡请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          const params = {
            id: this.form_queryCards.id,
            num: this.nums,
            money: this.rechargeAmount,
            addmoney: this.form_queryCards.spend,
          };
          var result = this.$http.post("/api/v1/applycard", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (res.data.code == "200") {
                this.$message({
                  message: "开卡成功!",
                  type: "success",
                }),
                  (this.dialogVisible = false),
                  loading.close();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                }),
                  loading.close();
              }
            },
            (error) => {
              console.log(error);
              loading.close();
            }
          );
        } else if (this.waytype == "2") {
          this.lods = Loading.service({
            lock: true,
            text: "开卡请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let params = {
            id: this.form_queryCards.id,
            num: this.nums,
            money: this.rechargeAmount,
            addmoney: this.form_queryCards.spend,
            clientid: this.$cookie.get("client_id"),
          };
          params = this.$lmjm(params);
          var result = this.$http.post("/api/v1/applyYicard", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (
                res.data.code == "204" ||
                res.data.code == "406" ||
                res.data.code == "408" ||
                res.data.code == "401" ||
                res.data.code == "407" ||
                res.data.code == "500"
              ) {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
                this.lods.close();
              }
              // if (res.data.code == "200") {
              //   this.$message({
              //     message: '开卡成功!',
              //     type: 'success'
              //   }), this.dialogVisible = false, loading.close();
              // } else {
              //   this.$message({
              //     message: res.data.msg,
              //     type: 'error'
              //   }), loading.close();
              // }
            },
            (error) => {
              console.log(error);
              this.lods.close();
            }
          );
        } else if (this.waytype == "3" && this.types == "share") {
          this.lods = Loading.service({
            lock: true,
            text: "开卡请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let params = {
            id: this.form_queryCards.id,
            num: this.nums,
            money: this.rechargeAmount,
            addmoney: this.form_queryCards.spend,
            clientid: this.$cookie.get("client_id"),
          };
          params = this.$lmjm(params);
          var result = this.$http.post("/api/v1/applyPincard", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (
                res.data.code == "204" ||
                res.data.code == "406" ||
                res.data.code == "408" ||
                res.data.code == "401" ||
                res.data.code == "407" ||
                res.data.code == "500"
              ) {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
                this.lods.close();
              }
            },
            (error) => {
              console.log(error);
              this.lods.close();
            }
          );
        } else if (this.waytype == "3" && this.types == "recharge") {
          this.lods = Loading.service({
            lock: true,
            text: "开卡请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let params = {
            id: this.form_queryCards.id,
            num: this.nums,
            addmoney: this.form_queryCards.spend,
            clientid: this.$cookie.get("client_id"),
          };
          params = this.$lmjm(params);
          var result = this.$http.post("/api/v1/applyPincard", params, {
            headers: {
              token: window.localStorage.getItem("token"),
            },
          });
          result.then(
            (res) => {
              if (
                res.data.code == "204" ||
                res.data.code == "406" ||
                res.data.code == "408" ||
                res.data.code == "401" ||
                res.data.code == "407" ||
                res.data.code == "500"
              ) {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
                this.lods.close();
              }
            },
            (error) => {
              console.log(error);
              this.lods.close();
            }
          );
        }
      },
      5000,
      {
        trailing: false,
      }
    ),
    closeDialog() {
      this.nums = "1";
      this.rechargeAmount = "0";
      this.form_queryCards.spend = "0";
    },

    initWebSocket() {
      // 初始化weosocket
      const token = window.localStorage.getItem("token");
      const wsuri = "wss://" + this.$domain + "/wss?token=" + token;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      const actions = {
        type: "login",
        client_name: "yy",
        room_id: "1",
      };
      this.websocketsend(JSON.stringify(actions));
      this.resetsinter = setInterval(this.heartbeat, 30000);
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data);
    },
    websocketonerror(e) {
      // 连接建立失败重连
      this.errorhandle(e);
    },
    websocketclose(e) {
      // 关闭
      this.errorhandle(e);
    },
    errorhandle(e) {
      console.log("连接异常，尝试重连");
      // 连接建立失败，或者连接被关闭，全都到这儿来
      this.reconnectnum++;
      // 允许重连3次，不然这里会无限重连，给服务器带来非常大的压力。
      if (this.reconnectnum < 3) {
        if (this.resetsinter) {
          clearInterval(this.resetsinter);
          this.resetsinter = null;
          this.websock.close();
        }
        this.initWebSocket();
        console.log("断开连接", e);
      } else {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
        // 关闭重连
        console.log(
          "超过3次了，关闭前端websocket所有服务，只能下次刷新后重新尝试连接,因为服务端close连接而导致的前端无限重连"
        );
        // this.websock.close()
      }
    },
    refreshcon() {
      console.log("后端如果向前端发送了websocket");
      // 后端如果向前端发送了websocket，则重新开始计时，过30秒后再发心跳 。
      if (this.resetsinter) {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
      }
      this.resetsinter = setInterval(this.heartbeat, 30000);
      this.reconnectnum = 0;
    },
    heartbeat() {
      const clock = {
        type: "pong",
      };
      this.websocketsend(JSON.stringify(clock));
    },
    websocketonmessage(e) {
      // 数据接收
      console.log(e);
      const data = JSON.parse(e.data);
      switch (data.type) {
        // 服务端ping客户端
        case "firstping":
          const ty = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(ty));
          this.$cookie.set("client_id", data.client_id);
          break;
        case "ping":
          const clock = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(clock));
          console.log("心跳回复");
          break;
        case "pingsuccess":
          console.log("ping成功");
          break;
        case "loginsuccess":
          console.log("登录成功");
          break;
        case "wxpay":
          console.log("微信支付结果通知");
          if (data.statu == 2) {
            console.log("微信支付成功");
          } else {
            console.log("微信支付失败");
          }
          break;
        case "real":
          console.log("支付宝实名认证结果通知");
          if (data.statu == 2) {
            console.log(
              "实名认证效验通过，和注册身份证信息对应，前端做进一步的提示和处理"
            );
          } else {
            console.log(
              "实名认证效验不通过，和注册身份证信息不对应，前端做进一步的提示和处理"
            );
          }
          break;
        case "downcard":
          console.log("下载最新卡片余额记录进度通知");
          if (data.statu == 2) {
            console.log("同步完成了一个".data.data);
            console.log(
              "全部同步完了，进度完成，可以开始下载了，前端需获悉用户下载的总数，然后不断地递减1,但不管的递减多少，3分钟后进度条就全部跑完。"
            );
          }
          break;
        case "hfpay":
          if (data.statu == 2) {
            console.log("第三方支付成功");
          } else {
            console.log("第三方支付失败");
          }
          break;
        case "gopencard":
          this.refreshcon();
          console.log(
            "开卡成功一张!!" + this.successCardNums + "-" + this.nums
          );

          if (data.statu == 2) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡成功 " + this.successCardNums + "/" + this.nums,
              type: "success",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          if (data.statu == 1) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡失败1张!",
              type: "error",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          break;
        case "opencard":
          this.refreshcon();
          console.log("开卡成功一张!!");
          if (data.statu == 2) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡成功 " + this.successCardNums + "/" + this.nums,
              type: "success",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          if (data.statu == 1) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡失败1张!",
              type: "error",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          break;
      }
    },
  },
  created() {
    this.getUserInfo();
    this.initWebSocket();
  },
  mounted() { },
};
</script>


<style lang="less" scoped>
/deep/ .el-input__inner {
  border-radius: 8px;
}

/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/deep/ .btn-next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  width: 36px;
  height: 36px;
  border: 1px solid #dee2e6;
  border-radius: 50% !important;
}

/deep/ .btn-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  width: 36px;
  height: 36px;
  border: 1px solid #dee2e6;
  border-radius: 50% !important;
}

/deep/ .el-pager .number {
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  color: #8392ab;
  background-image: #e9ecef;
}

/deep/ .el-pager .active {
  color: #fff;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.zd_n_box {
  width: 50%;
  display: inline-block;
}

.zd_box {
  width: 100%;
  margin: 0 auto;
}



i {
  padding-right: 5px;
}

.cardHead {
  font-size: 0.9em;
  text-align: center;
  padding: 2% 0%;
}

.bg-card {
  background: #fff;
  -webkit-box-shadow: 4px 4px 40px rgb(185, 192, 199, 0.3);
  box-shadow: 4px 4px 40px rgb(185, 192, 199, 0.3);
  border-color: rgb(185, 192, 199, 0.3);
  font-size: 1.5em;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}

.cardTitle {
  font-size: 0.5em;
  margin-left: 4%;
  padding-bottom: 4%;
}

.el-descriptions {
  padding-bottom: 5% !important;
  margin-left: 4% !important;
  margin-right: 4% !important;
}

.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 60px;
  }

  .right {
    float: right;
    width: 60px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }
}

.tips {
  position: relative;
  width: 93%;
  top: 0;
  margin-bottom: 5%;
}

.el-col-6 {
  padding: 0 10px !important;
}

.dh {
  position: relative;
  -moz-box-shadow: 0px 0px 3px 1px rgb(185, 192, 199, 0.3);
  -webkit-box-shadow: 0px 0px 3px 1px rgb(185, 192, 199, 0.3);
  box-shadow: 0px 0px 3px 1px rgb(185, 192, 199, 0.3);
  margin-bottom: 40px;
}

.dh:hover {
  -webkit-animation: upmove 1s ease;
  -o-animation: upmove 1s ease;
  animation: upmove 1s ease;
  animation-fill-mode: forwards;
}

@keyframes upmove {
  from {
    bottom: 0px;
    -moz-box-shadow: 0px 0px 0px 0px #ffffff;
    -webkit-box-shadow: 0px 0px 0px 0px #ffffff;
    box-shadow: 0px 0px 0px 0px #ffffff;
  }

  to {
    -moz-box-shadow: 0px 0px 10px 6px rgb(64, 158, 255, 0.2);
    -webkit-box-shadow: 0px 0px 10px 6px rgb(64, 158, 255, 0.2);
    box-shadow: 0px 0px 10px 6px rgb(64, 158, 255, 0.2);
    bottom: 10px;
  }
}

.el-input {
  width: 90%;
}

.dialog-footer {
  width: 50%;
  position: relative;
  left: 75%;
}

.ico_box {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 13.5px;
    height: 13.5px;
  }
}

.success {
  border: 1px solid #82d616;
}

.error {
  border: 1px solid #ea0606;
}

.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.applybtn {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  transition: all 200ms ease-out;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 4px 7px -1px,
    rgba(0, 0, 0, 0.07) 0px 2px 4px -1px;
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

.applybtn:hover {
  transform: scale(1.04);
}

.applybtn_dis {
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
}

.table_svg_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.table_svg {
  width: 47px;
  height: 28px;
}
</style>
